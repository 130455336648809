import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

// kendo ui for angular
import { GridModule } from "@progress/kendo-angular-grid";
import { DropDownsModule, MultiSelectModule } from "@progress/kendo-angular-dropdowns";
import { TextBoxModule, TextAreaModule, NumericTextBoxModule, MaskedTextBoxModule } from "@progress/kendo-angular-inputs";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs"
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogModule } from "@progress/kendo-angular-dialog";
import { TooltipModule } from "@progress/kendo-angular-tooltip";
import { IntlModule } from '@progress/kendo-angular-intl';

import { DebugDisplayComponent } from "./components/debugInfo.component";
import { ErrorDisplayComponent } from "./components/errorDisplay.component";

// Form components
import { FpLabelComponent } from "./components/forms/fpLabel.component";
import { FpLabelInfoComponent } from "./components/forms/fpLabelInfo.component";
import { FpIconComponent } from "./components/forms/fpIcon.component";
import { FpTextboxComponent } from "./components/forms/fpTextbox.component";
import { FpMultiSelectComponent } from "./components/forms/fpMultiSelect.component";
import { FpCheckboxComponent } from "./components/forms/fpCheckbox.component";
import { FpDropdownComponent } from "./components/forms/fpDropdown.component";
import { FpTextareaComponent } from "./components/forms/fpTextarea.component";
import { FpValidationErrorComponent } from "./components/forms/fpValidationError.component";
import { FpNumericTextboxComponent } from "./components/forms/fpNumericTextbox.component";
import { FpDatePickerComponent } from "./components/forms/fpDatePicker.component";
import { FpButtonGroupComponent } from "./components/forms/fpButtonGroup.component"
import { FpMaskedTextboxComponent } from "./components/forms/fpMaskedTextbox.component";
import { FpModalDialogComponent } from "./components/forms/fpModalDialog.component";
import { FpPasswordComponent } from "./components/forms/fpPassword.componet";

// Grid components
import { FpMultiSelectFilterComponent } from "./components/grid/fpMultiSelectFilter.component";
import { FpPagerComponent } from "./components/grid/fpPager.component";

// Directives
import { FpBlockUiContainerDirective } from "./directives/fpBlockUiContainer.directive"
import { FpBlockUiDirective } from "./directives/fpBlockUi.directive";
import { FpTranslateDirective } from "./directives/fpTranslate.directive";
import { FpGridBindingDirective } from "./directives/fpGridBinding.directive";

// Validators
import { DateRequiredValidator } from "./validators/dateRequired.validator";
import { DateRangeValidator } from "./validators/dateRange.validator";

@
NgModule({
    imports: [
        CommonModule,
        FormsModule,
        GridModule,
        DropDownsModule,
        MultiSelectModule,
        TextBoxModule,
        TextAreaModule,
        NumericTextBoxModule,
        MaskedTextBoxModule,
        DateInputsModule,
        ButtonsModule,
        DialogModule,
        TooltipModule,
        IntlModule,
        FontAwesomeModule
    ],
    declarations: [
        FpLabelComponent,
        FpLabelInfoComponent,
        FpIconComponent,
        FpTextboxComponent,
        FpMultiSelectComponent,
        FpCheckboxComponent,
        FpDropdownComponent,
        FpTextareaComponent,
        FpNumericTextboxComponent,
        FpButtonGroupComponent,
        FpValidationErrorComponent,
        FpDatePickerComponent,
        FpMaskedTextboxComponent,
        FpModalDialogComponent,
        FpMultiSelectFilterComponent,
        FpPagerComponent,
        DebugDisplayComponent,
        ErrorDisplayComponent,
        FpBlockUiContainerDirective,
        FpBlockUiDirective,
        FpTranslateDirective,
        FpGridBindingDirective,
        FpPasswordComponent,
        DateRequiredValidator,
        DateRangeValidator
    ],
    exports: [
        FpLabelComponent,
        FpLabelInfoComponent,
        FpIconComponent,
        FpTextboxComponent,
        FpMultiSelectComponent,
        FpCheckboxComponent,
        FpDropdownComponent,
        FpTextareaComponent,
        FpNumericTextboxComponent,
        FpButtonGroupComponent,
        FpValidationErrorComponent,
        FpDatePickerComponent,
        FpMaskedTextboxComponent,
        FpModalDialogComponent,
        FpMultiSelectFilterComponent,
        FpPagerComponent,
        DebugDisplayComponent,
        ErrorDisplayComponent,
        FpBlockUiContainerDirective,
        FpBlockUiDirective,
        FpTranslateDirective,
        FpGridBindingDirective,
        FpPasswordComponent,
        DateRequiredValidator,
        DateRangeValidator
    ]
})
export class SharedModule {
}