<div class="container container-fluid container-full">
    <fp-nav></fp-nav>
</div>

<div id="page" class="container container-fluid container-full">
    <div id="main" fpBlockUiContainer>
        <router-outlet></router-outlet>
    </div>
    <!--End main-->
    <div id="footer" *ngIf="!mainContextService.isFullScreen">
        <div class="colofon">
            Fost Plus - {{appName}}
        </div>
    </div>
</div>

<fp-modal-dialog #leavePageDialog header="MyApp.LeavePageTitle"
                 content="MyApp.LeavePage"
                 (result)="leavePageDialogResult($event)">
</fp-modal-dialog>
