import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from "@angular/common/http";

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const jsonDateRegex = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)/;

@Injectable()
export class ResponseToPoJoInterceptor implements HttpInterceptor {


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            map(event => {
                if (event instanceof HttpResponse) {
                    var body = JSON.parse(event.body, this.reviver);

                    event = event.clone({ body: body });
                }
                return event;
            }));

    }

    private reviver(name, value) {
        if (typeof (value) === "string" && jsonDateRegex.test(value)) {
            try {
                var date = new Date(value);
                return date;
            } catch (e) {
            }
        }

        return value;
    }
}