import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from "@angular/common/http";

import { Observable } from 'rxjs';

import * as moment from "moment";

@Injectable()
export class RequestToJsonInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.body != null) {
            var body = JSON.stringify(req.body, this.jsonReplacer);

            var request = req.clone({
                body: body,
            });

            return next.handle(request);
        } else {
            return next.handle(req);
        }

    }

    private jsonReplacer(name, value) {
        var that = this;

        if (name.substring(0, 2) === "$$") {
            return undefined;
        }

        if (value && that[name] instanceof Date) {
            let m = moment(that[name]);
            return m.format("YYYY-MM-DDTHH:mm:ss");
        }

        return value;
    }
}