import { Injectable, Inject, EventEmitter } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { FpCacheServiceFactory, FpCacheService, FpCacheStoragesEnum } from "./fpCacheService.service";
import { MainContext } from "../../../ScriptsModels/MainContext";
import { ConfigurationService } from "./configuration.service";
import { LogService } from "./log.service";
import { Router, ActivatedRoute } from "@angular/router";

import UsersContract = FostPlus.Olympus.UsersDomain.Clients.Api;

@Injectable()
export class MainContextService {
    constructor( @Inject("appName") private appName: string,
        private titleService: Title,
        private fpCacheServiceFactory: FpCacheServiceFactory,
        private configurationService: ConfigurationService,
        private logService: LogService,
        private router: Router,
        public route: ActivatedRoute) {
        this.currentContext = { isFullScreen: false };
        this.titleService.setTitle(this.appName);

        this._localStorageCache = fpCacheServiceFactory.getOrCreate(FpCacheStoragesEnum.LOCAL_STORAGE, "mainContextService");

        var fullScreen: boolean = this._localStorageCache.get(this.fullScreenCacheKey);

        if (fullScreen) {
            this.currentContext.isFullScreen = fullScreen;
        }
    }

    private fullScreenCacheKey: string = "user_fullScreen";
    private _titleText: string;
    private _localStorageCache: FpCacheService;

    // private _userAccesses: Array<UsersContract.IUserAccessDto>;
    
    public currentContext: MainContext;

    public onRefreshRequested: EventEmitter<boolean> = new EventEmitter<boolean>();
    
    // title
    public set title(title: string) {
        this._titleText = title;

        if (this._titleText && this._titleText.length > 0) {
            this.titleService.setTitle(this.appName + " - " + this._titleText);
        } else {
            this.titleService.setTitle(this.appName);
        }
    }

    // headerTitle
    public get headerTitle() {
        if (this._titleText) {
            return this._titleText;
        } else {
            return "";
        }
    }

    // isFullScreen
    public get isFullScreen(): boolean {
        return this.currentContext.isFullScreen;
    }

    public toggleFullScreen(): void {
        this.currentContext.isFullScreen = !this.currentContext.isFullScreen;
        this._localStorageCache.set(this.fullScreenCacheKey, this.currentContext.isFullScreen);
        this.refreshBodyOffset();
    }

    public navigate(path: string) {
        if (path === null || path == "") {
            path = "dashboard";
        }
        
        this.router.navigateByUrl(path, { replaceUrl: true });
    }

    public refreshBodyOffset() {
        // set padding-top for body ( to take into account the subMenu )
        setTimeout(() => {
            const body = document.getElementsByTagName('body')[0];
            if (!this.isFullScreen) {
                body.classList.remove('fullScreen');
            } else {
                body.classList.add('fullScreen');
            }
        });
    }
}