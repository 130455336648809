<div [ngClass]="{'form-group control-wrapper': !readMode, 'readmode-wrapper': readMode, 'error': model && model.invalid && model.dirty}">
    <ng-container *ngIf="readMode">
        <label class="control-normal-label" *ngFor="let item of getSelectedButtonGroupItem()">
            <span *ngIf="item.translationKey" fp-translate>{{ item.translationKey }}</span>
            <span *ngIf="!item.translationKey">{{ item.name }}</span>
        </label>
    </ng-container>
    <table *ngIf="!readMode" class="table-fixed">
        <tr>
            <td class="buttongroup-container">
                <div>
                    <kendo-buttongroup [id]="controlId"
                                       selection="single"
                                       [disabled]="disabled"
                                       [width]="width">
                        <!--[width]="auto"
                        => kan toegevoegd worden wanneer je wil dat buttons altijd stretched worden tov group (en group krijgt width van parent, dus vaak col-md)
                        => padding moet dan best van button zelf gehaald worden (zie styling hieronder)-->
                        <button kendoButton
                                type="button"
                                *ngFor="let button of data"
                                [togglable]="true"
                                [selected]="isSelected(button)"
                                [disabled]="button.disabled || disabled"
                                (selectedChange)="toggleButton(button.value, $event)">
                            <span *ngIf="button.translationKey" fp-translate>{{button.translationKey}}</span>
                            <span *ngIf="!button.translationKey">{{button.name}}</span>
                        </button>
                    </kendo-buttongroup>
                </div>
                <div *ngIf="model && model.invalid && model.dirty" class="tooltip-validation ng-star-inserted">
                    <fa-icon icon="exclamation-circle"></fa-icon>
                </div>
            </td>
        </tr>
        <tr *ngIf="model && model.invalid && model.dirty">
            <td>
                <div>
                    <fp-validation-error [for]="name" [errors]="model?.errors"></fp-validation-error>
                </div>
            </td>
        </tr>
    </table>
</div>