import { Injectable } from '@angular/core';

import User = FostPlus.User.Web.Gui

@Injectable()
export class ConfigurationService {
    public appSettings: User.ISettings;
    //private hasRoleFpAdmin: boolean;
    //private hasRoleOpAdminSup: boolean;
    private isRootLoaded: boolean;
    private requestLanguage: string;
    private cacheValidity: number = 7200;

    public setAppSettings(settings: User.ISettings): void {
        this.appSettings = settings;

        //settings.roles.forEach((role) => {
        //    this.hasRoleFpAdmin = (role == 'FpAdmin');
        //    this.hasRoleOpAdminSup = (role == 'OpAdminSup');
        //});
    }

    public isAppSettingsLoaded(): boolean {
        if (this.appSettings) {
            return true;
        }

        return false;
    }

    //public getOlympusUserType(): number {
    //    if (this.appSettings) {
    //        return this.appSettings.olympusUserTypeId;
    //    }

    //    return null;
    //}

    //public getIsInternalUser(): boolean {
    //    var isInternalUser = false;

    //    if (this.appSettings) {
    //        isInternalUser = (this.appSettings.olympusUserTypeId === 2);
    //    }

    //    return isInternalUser;
    //}

    public getAntiForgeryToken(): string {
        if (this.appSettings) {
            return this.appSettings.antiForgeryToken;
        }

        return null;
    }

    public getInitialLanguage(): string {
        if (this.appSettings) {
            return this.appSettings.initialLanguage;
        }

        return null;
    }

    public isDebug(): boolean {
        if (this.appSettings) {
            return this.appSettings.isDebug;
        }

        return false;
    }

    //public getOlympusUserId(): number {
    //    if (this.appSettings) {
    //        return this.appSettings.olympusUserId;
    //    }

    //    return null;
    //}

    //public getRoles(): string[] {
    //    if (this.appSettings) {
    //        return this.appSettings.roles;
    //    }

    //    return null;
    //}

    public getShowTranslationOption(): boolean {
        if (this.appSettings) {
            return this.appSettings.showTranslationOption;
        }

        return false;
    }

    //public getUsername(): string {
    //    if (this.appSettings) {
    //        return this.appSettings.username;
    //    }

    //    return null;
    //}

    // obsolete, registration moved to myfost
    //public getOnlineDeclarationConditions_NL_Uri(): string {
    //    if (this.appSettings) {
    //        return this.appSettings.onlineDeclarationConditions_NL_Uri;
    //    }

    //    return null;
    //}

    // obsolete, registration moved to myfost
    //public getOnlineDeclarationConditions_FR_Uri(): string {
    //    if (this.appSettings) {
    //        return this.appSettings.onlineDeclarationConditions_FR_Uri;
    //    }

    //    return null;
    //}

    // obsolete, registration moved to myfost
    //public getOnlineDeclarationConditions_EN_Uri(): string {
    //    if (this.appSettings) {
    //        return this.appSettings.onlineDeclarationConditions_EN_Uri;
    //    }

    //    return null;
    //}

    public getFostplus_Homepage(): string {
        if (this.appSettings) {
            return this.appSettings.fostplus_Homepage;
        }

        return null;
    }
    public getMyFostBaseUri(): string {
        if (this.appSettings) {
            return this.appSettings.myFostBaseUri;
        }

        return null;
    }

    public getFostPackBaseUri(): string {
        if (this.appSettings) {
            return this.appSettings.fostPackBaseUri;
        }

        return null;
    }

    public getEpackBaseUri(): string {
        if (this.appSettings) {
            return this.appSettings.epackBaseUri;
        }

        return null;
    }

    public getReCaptchaPublicKey(): string {
        if (this.appSettings) {
            return this.appSettings.reCaptchaPublicKey;
        }

        return null;
    }

    //public hasFpAdminRole(): boolean {
    //    if (this.hasRoleFpAdmin) {
    //        return this.hasRoleFpAdmin;
    //    }

    //    return false;
    //}

    //public hasOpAdminSupRole(): boolean {
    //    if (this.hasRoleOpAdminSup) {
    //        return this.hasRoleOpAdminSup;
    //    }

    //    return false;
    //}

    public setRequestLanguage(language: string): void {
        this.requestLanguage = language;
    }

    public getRequestLanguage(): string {
        if (this.requestLanguage) {
            return this.requestLanguage;
        }

        return null;
    }

    public getAllowNoCaptchaUrlParameter(): boolean {
        if (this.appSettings) {
            return this.appSettings.allowNoCaptchaUrlParameter;
        }

        return null;
    }

    // Cache validity in seconds
    public getCacheValidity(): number {
        return this.cacheValidity;
    }

    //public getParameters(): FostPlus.CoreDomain.Services.Contract.Dtos.IMyFostParametersDto {
    //    return this.appSettings.parameters;
    //}
}