import { Injectable } from "@angular/core";
import { Router, Event, RoutesRecognized, NavigationStart, NavigationCancel, NavigationEnd, NavigationError } from "@angular/router";
import * as moment from "moment";
import { ConfigurationService } from "../services/configuration.service";
import { LogService } from "./log.service";

export interface IAnalyticsTrackingData {
    event: NavigationStart;
    start: Date;
}

@Injectable()
export class AnalyticsService {
    private appInsights = (<any>window).appInsights;

    constructor(private config: ConfigurationService,
        private logService: LogService,
        private router: Router) {

        this.timings = new Map<number, IAnalyticsTrackingData>();
        this.router.events.subscribe(this.routerEvent);
    }
    
    private timings: Map<number, IAnalyticsTrackingData>;

    private routerEvent = (event: Event) => {
        if (event instanceof NavigationStart) {
            // Log time and save until RouteRecognized
            this.timings.set(event.id, { event: event, start: new Date() });
        } else if (event instanceof RoutesRecognized) {
            // Add start date to root ActivatedRouteSnapshot
            var data = this.timings.get(event.id);

            if (data && data.start) {
                event.state.root["start"] = data.start;
            } else {
                this.logService.warn("No timings found for request :", event);
                event.state.root["start"] = new Date();
            }
        } else if (event instanceof NavigationCancel ||
            event instanceof NavigationEnd ||
            event instanceof NavigationError) {
            // remove timings for ended/cancelled/errored navigations
            this.timings.delete(event.id);

            if (event instanceof NavigationError) {
                this.trackException(event.error);
            }
        }
    }

    public controllerDataLoaded(name: string, loadStart: Date) {
        // app insights
        if (this.appInsights && name && loadStart) {

            var loadEnd = new Date();

            var ms = moment(loadEnd).diff(moment(loadStart));
            if (this.config.isDebug()) {
                this.logService.info('trackPageView ' + name + ' ' + ms + 'ms');
            }

            this.appInsights.trackPageView({ name: name }, { duration: ms });
        }
    }

    public measureLoadDataEvent(name: string, start: Date) {
        if (this.appInsights) {
            var end = new Date();
            var ms = moment(end).diff(moment(start));
            if (this.config.isDebug()) {
                this.logService.info('measureLoadDataEvent ' + name + ' ' + ms + 'ms');
            }
            this.appInsights.trackEvent(name, null, {
                loadTime: ms
            });
        }
    }

    public logEvent(name: string, properties: any) {
        if (this.appInsights) {
            if (this.config.isDebug()) {
                this.logService.info('event ' + name);
            }
            this.appInsights.trackEvent(name, properties);
        }
    }

    public trackException(exception: any, properties?: any) {
        if (this.appInsights) {
            this.appInsights.trackException(exception, "handled", properties);
        }

        this.logService.error(exception);
    }
}