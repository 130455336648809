import { Component } from "@angular/core"
import { MenuService } from "../services/menu.service";
import { MenuItem, RouteMenuItem, UrlMenuItem } from "../../../ScriptsModels/MenuItem"

@Component({
    //moduleId: module.id,
    selector: "fp-menu",
    templateUrl: "./menu.component.html",
    // Limit the change tracking on this component
    //changeDetection: ChangeDetectionStrategy.OnPush
})

export class MenuComponent {
    constructor(public menuService: MenuService) {
    }

    public isRouteMenuItem(item: MenuItem): boolean {
        if (item instanceof RouteMenuItem) {
            return true;
        }

        return false;
    }

    public resolveUrl(item: UrlMenuItem): string {
        return this.menuService.resolveUrl(item);
    }

    public resolveRoute(item: RouteMenuItem): Array<any> {
        return this.menuService.resolveRoute(item);
    }

    public resolveRouteParams(item: RouteMenuItem): Object {
        return this.menuService.resolveRouteParams(item);
    }
}