import { Component, OnInit, ViewChild } from "@angular/core";
import { BaseRouteComponent } from "../base/baseRoute.component";
import { BaseRouteDependencies, BaseRouteDepenciesFactory, baseRouteDeps } from "../base/baseRouteDependencies.provider";
import { RecaptchaComponent } from "ng-recaptcha";
import { UserRepository } from "../../repositories/user.repository";
import { DataTask } from "../../classes/dataTask"
import UserContract = FostPlus.Olympus.UsersDomain.Clients.Api;
import FpCore = FostPlus.Olympus.CoreDomain.Clients.Api;
import { Params } from "@angular/router";

@Component({
    templateUrl: "./userIAMRegistrationAnonymous.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})
export class UserIAMRegistrationAnonymousComponent extends BaseRouteComponent<UserContract.IUserIAMRegistrationAnonymousDto>
    implements OnInit {
    @ViewChild("invisibleCaptcha", { static: false })
    public invisibleCaptcha?: RecaptchaComponent;
    private captchaSucceeded = false;

    public introSteps: string = "";
    public hasAccess: boolean = false;
    public showUserName: boolean = false;
    public showPartyNumber: boolean = false;
    public showCreateIAM: boolean = false;
    public showRequestSentInfo: boolean = false;
    public unknownPartyId: boolean = false;
    public activeIAMEmail: string = "";
    public linkToTermsOfUseNl: string = '';
    public linkToTermsOfUseFr: string = '';
    public linkToTermsOfUseEn: string = '';

    public luLanguages: Array<FpCore.ILuLanguageTrInfoDto>;
    public luGenders: Array<FpCore.ILuGenderTrInfoDto>;

    constructor(baseRouteDeps: BaseRouteDependencies,
        private userRepository: UserRepository) {
        super("UserIAMRegistrationAnonymousComponent", baseRouteDeps);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    protected configure() {
        this.baseRouteDeps.mainContextService.title = "";

        this.data = this.getEmptyData();

        var appSettings = this.baseRouteDeps.configuration.appSettings;
        // obsolete, registration moved to myfost
        //this.linkToTermsOfUseNl = appSettings.linkToTermsOfUse_Nl;
        //this.linkToTermsOfUseFr = appSettings.linkToTermsOfUse_Fr;
        //this.linkToTermsOfUseEn = appSettings.linkToTermsOfUse_En;

        this.addDataTask(new DataTask(this.loadLuLanguageData, this.processLuLanguageData, { reloadOnCultureChange: true, reload: false }));
        this.addDataTask(new DataTask(this.loadLuGenderData, this.processLuGenderData, { reloadOnCultureChange: true, reload: false }));

        this.processQueryParams();
    }

    protected queryParamsUpdated(params: Params): void {
        var reload = this.processQueryParams();
        this.clearFeedback();

        if (reload) {
            this.reload();
        }
    }

    private processQueryParams(): boolean {
        var lang = <string>this.queryParams["locale"];
        var langChanged = lang && lang.toLowerCase() !== this.baseRouteDeps.languageService.getLanguage();

        if (langChanged) {
            this.baseRouteDeps.languageService.updateLanguage(lang);
        }

        return !langChanged;
    }

    protected destroy() {
    }

    private getEmptyData(): UserContract.IUserIAMRegistrationAnonymousDto {
        return {
            email: '',
            firstName: '',
            hasAgreedToTermsOfUse: null,
            id: null,
            lastName: '',
            luGenderId: null,
            luLanguageId: null,
            rowVersion: null,
            userName: '',
            hasDeclaredNoIAM: null,
            partyNumber: '',
            iamRegistrationComment: '',
            organisationIdentificationNumberUnformatted: ''
        }
    }

    private loadLuLanguageData(resolve: (data: Array<FpCore.ILuLanguageTrInfoDto>) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.lookupDataRepository.getLuLanguageTrsInfo().then((result) => {
            resolve(result.result);
        }).catch((error) => {
            reject(error);
        });
    }

    private processLuLanguageData(data: Array<FpCore.ILuLanguageTrInfoDto>) {
        var languages = this.baseRouteDeps.lookupDataUtilities.filterCommunicationLanguages(data);
        this.luLanguages = languages;
    }

    private loadLuGenderData(resolve: (data: Array<FpCore.ILuGenderTrInfoDto>) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.lookupDataRepository.getLuGenderTrsInfo().then((result) => {
            resolve(result.result);
        }).catch((error) => {
            reject(error);
        });
    }

    private processLuGenderData(data: Array<FpCore.ILuGenderTrInfoDto>) {
        this.luGenders = data;
    }

    public checkUsernameAlreadyExists(): void {
        this.clearFeedback();

        if (this.data.userName && this.data.userName.length > 0) {
            this.userRepository.checkUsernameAvailability(this.data.userName).then(result => {
                if (result.isAvailable) {
                    this.showWindowsLogonNotInUseSuccessToast();
                } else {
                    this.showWindowsLogonAlreadyExistsErrorToast();
                }
            }).catch(error => {
                this.handleFeedback(error);
            });
        }
    }    

    private showWindowsLogonAlreadyExistsErrorToast = () => {
        this.baseRouteDeps.translateService.get("Users.User_Validation_WindowsLogonNotUnique").subscribe((translation) => {
            this.baseRouteDeps.toastr.error(translation);
        });
    };

    private showWindowsLogonNotInUseSuccessToast = () => {
        this.baseRouteDeps.translateService.get("MyApp.WindowsLogonNotInUse").subscribe((translation) => {
            this.baseRouteDeps.toastr.success(translation);
        });
    };

    public save(): void {
        this.clearFeedback();

        this.showRequestSentInfo = false;

        if (this.isFormValid() && !this.isBlocking() && this.data.hasAgreedToTermsOfUse == true && this.data.hasDeclaredNoIAM == true) {
            this.startBlocking();
            if (this.showUserName == false || !this.data.userName) {
                this.data.userName = this.data.email;
            }

            //Check email as username before saving
            if (this.data.userName == this.data.email) {
                if (this.data.email) {
                    this.userRepository.checkUsernameAvailability(this.data.email).then(result => {
                        if (result.isAvailable) {
                            this.UserIAMRegistrationAnonymousCreateOrUpdate();
                        } else {
                            this.showWindowsLogonAlreadyExistsErrorToast();
                            this.showUserName = true;
                            this.stopBlocking();
                        }
                    }).catch(error => {
                        this.showUserName = true;
                        this.handleFeedback(error);
                        this.stopBlocking();
                    });
                }
            }
            else {
                this.UserIAMRegistrationAnonymousCreateOrUpdate();
            }
        }
    }

    private UserIAMRegistrationAnonymousCreateOrUpdate() {
        const request: UserContract.IUserIAMRegistrationAnonymousCreateOrUpdateRequest = {
            userIAMRegistration: this.data,
        };

        this.userRepository.UserIAMRegistrationAnonymousCreateOrUpdate(request)
            .then(result => {
                this.refreshData(result.userIAMRegistration, this.processUserIAMRegistrationAnonymous, true);
                this.showSaveConfirmation();
                this.scrollToTop();
                this.stopBlocking();

                this.showRequestSentInfo = true;
            }).catch((error) => {
                this.handleFeedback(error, request);
                this.stopBlocking();
                this.scrollToTop();
            });
    }

    public onResolveCaptcha(reCaptchaToken: string) {
        // try to load existing useriamregistration
        if (reCaptchaToken == null) {
            this.captchaSucceeded = false;
            return;
        } else {
            this.captchaSucceeded = true;
        }

        this.loadUserIAMRegistration(true, reCaptchaToken);
    }

    public resetShowCreateIAM(): void {
        this.showCreateIAM = false;
    }

    private loadUserIAMRegistration(withCaptcha: boolean, reCaptchaToken: string = '') {
        var self = this;
        if (this.isFormValid() && !self.isBlocking()) {
            self.startBlocking();

            var okFunc = function (result: UserContract.IUserIAMRegistrationAnonymousGetToTreatResponse) {
                if (result.activeIAMEmail) {
                    self.activeIAMEmail = result.activeIAMEmail;
                }
                if (result.isNonMemberOrganisation == true) {
                    self.showPartyNumber = false;
                    self.showCreateIAM = true;
                }
                else if (result.isNonMemberOrganisation == false) {
                    self.showPartyNumber = true;
                    self.unknownPartyId = result.unknownPartyId;
                    if (self.unknownPartyId) {
                        self.showCreateIAM = false;
                    }
                    else if (self.data.partyNumber) {
                        self.showCreateIAM = true;
                    }
                }
                if (result.userIAMRegistrationAnonymous) {
                    self.processUserIAMRegistrationAnonymous(result.userIAMRegistrationAnonymous);
                }
                self.stopBlocking();
            }

            var errorFunc = function (error) {
                self.handleFeedback(error);
                self.stopBlocking();
                self.scrollToTop();
            }

            if (withCaptcha) {
                self.userRepository.UserIAMRegistrationAnonymousGetWithCaptcha(self.data.organisationIdentificationNumberUnformatted, self.data.partyNumber, reCaptchaToken)
                    .then((result) => {
                        okFunc(result);
                    }).catch((error) => {
                        errorFunc(error);
                    });
            } else {
                self.userRepository.UserIAMRegistrationAnonymousGet(self.data.organisationIdentificationNumberUnformatted, self.data.partyNumber)
                    .then((result) => {
                        okFunc(result);
                    }).catch((error) => {
                        errorFunc(error);
                    });
            }
        }
    }

    private processUserIAMRegistrationAnonymous(data: UserContract.IUserIAMRegistrationAnonymousDto) {
        if (data) {
            this.data = data;
        }
        else {
            this.data = this.getEmptyData();
        }

        if (this.data.userName && this.data.userName != this.data.email) {
            this.showUserName = true;
        }
    }

    public submit() {
        this.clearFeedback();

        if (!this.noCaptcha) {
            if (this.captchaSucceeded) {
                // Captcha already succeeded, so load data without captcha 
                //  => we cannot reuse a captchaToken, so we had to split up in 2 calls, 1 with and 1 without captcha)
                this.loadUserIAMRegistration(false);
            } else {
                this.invisibleCaptcha.execute();
            }
        }
        else {
            this.loadUserIAMRegistration(false);
        }
    }
}
