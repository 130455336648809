import { Injectable, Inject } from '@angular/core';
import { HttpProvider } from '../services/http.provider';
import { ConfigurationService } from "../services/configuration.service";

import User = FostPlus.User.Web.Gui

@Injectable()
export class AppSettingsLoader {
    constructor(private http: HttpProvider,
        private config: ConfigurationService,
        @Inject('rootUrl') private rootUrl: string) {
    }

    // this one is called from app.module 
    //  => app waits for it
    public loadAppSettings(): Promise<any> {
        if (!this.config.isAppSettingsLoaded()) {
            return this.http.get<User.ISettings>(this.rootUrl + 'api/app/settings').then((data) => {
                this.config.setAppSettings(data);

                if ((<any>window).appInsights) {
                    (<any>window).appInsights.stopTrackEvent('initialLoad');
                }
            });
        } else {
            return new Promise((resolve) => {
                resolve({});
            });
        }
    }
}