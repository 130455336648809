import { Component, Input } from "@angular/core";

@Component({
    //moduleId: module.id,
    selector: "fp-label",
    templateUrl: "./fpLabel.component.html",
    host: { 'class': 'fp-control' }
})

export class FpLabelComponent {
    @Input() label: string;
    @Input() for: string;
    @Input() class: string;
}