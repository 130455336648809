import { Component } from "@angular/core";
import { Params } from "@angular/router";
import { BaseRouteDependencies, BaseRouteDepenciesFactory, baseRouteDeps } from "./base/baseRouteDependencies.provider";
import { BaseRouteComponent } from "./base/baseRoute.component";
import { UserRepository } from "../repositories/user.repository";
import { DataTask } from "../classes/dataTask"

import UsersDomain = FostPlus.Olympus.UsersDomain.Clients.Api;

interface IResetPasswordData {
    resetKey: string;
    resetKeyValid: boolean;
    password: string;
    confirmPassword: string;
    resetComplete: boolean;
}

@Component({
    templateUrl: "./fostpackResetPassword.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})
export class FostpackResetPasswordComponent extends BaseRouteComponent<IResetPasswordData> {
    constructor(baseRouteDeps: BaseRouteDependencies,
        private userRepository: UserRepository
    ) {
        super("FostpackResetPasswordComponent", baseRouteDeps);
    }

    public resetSuccessText: string = "";
    public passwordMisMatch = false;

    protected configure() {
        // Normally called when activating a menu item
        // In this case there is no menu item
        this.baseRouteDeps.mainContextService.refreshBodyOffset();
        this.data = this.getEmptyDetailData();

        this.dataLoadFailed = () => {
        };

        this.dataLoaded = () => {
            this.passwordChange();
        };

        this.addDataTask(new DataTask(this.checkResetKeyValidity, this.processResetKeyValidity, { reloadOnCultureChange: true, reload: true }));
        this.addDataTask(new DataTask(this.loadSuccessText, this.processSuccessText, { reloadOnCultureChange: true, reload: false }));

        this.processQueryParams();
    }

    protected queryParamsUpdated(params: Params): void {
        var reload = this.processQueryParams();
        this.clearFeedback();

        if (reload) {
            this.reload();
        }
    }

    private processQueryParams(): boolean {
        var key = <string>this.queryParams["key"];
        var lang = <string>this.queryParams["locale"];

        var langChanged = lang && lang.toLowerCase() !== this.baseRouteDeps.languageService.getLanguage();
        var keyChanged = key && (key.toLowerCase() !== this.data.resetKey);

        if (keyChanged) {
            this.data = this.getEmptyDetailData();
            this.data.resetKey = key.toLowerCase();
        }

        if (langChanged) {
            this.baseRouteDeps.languageService.updateLanguage(lang);
        }

        // do not reload when both key and lang changed
        // changing lang will also re-check if key is valid
        return keyChanged && !langChanged;
    }

    private getEmptyDetailData(): IResetPasswordData {
        return {
            resetKey: null,
            resetKeyValid: false,
            password: '',
            confirmPassword: '',
            resetComplete: false
        };
    }

    private checkResetKeyValidity(resolve: (data: UsersDomain.IResetKeyValidityCheckResponse) => void, reject: (reason: any) => void) {
        this.userRepository.validateFostpackResetKey(this.data.resetKey).then((result) => {
            resolve(result);
        }).catch((error) => {
            reject(error);
        });
    }

    private processResetKeyValidity(data: UsersDomain.IResetKeyValidityCheckResponse) {
        if (data.validationErrors && data.validationErrors.length > 0) {
            this.data.resetKeyValid = false;

            this.handleValidationErrors(data);
        } else {
            this.data.resetKeyValid = true;
        }
    }

    private loadSuccessText(resolve: (data: string) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.translateService.get("MyApp.UserPasswordReset_Success_Text_FP1").subscribe((value) => {
            resolve(value); 
        });
    }

    private processSuccessText(data: string) {
        var fostpackUri = this.baseRouteDeps.configuration.getFostPackBaseUri();
        var epackUri = this.baseRouteDeps.configuration.getEpackBaseUri();
        var text = data.replace("{Fostpack}", "<a href=\"" + fostpackUri + "\">Fostpack</a>");
        text = text.replace("{ePack}", "<a href=\"" + epackUri + "\">ePack</a>");
        text = text.replace("\r\n", "<br>");
        this.resetSuccessText = text;
    }

    public passwordChange(): void {
        this.passwordMisMatch = false;

        if (this.data.password.length > 9 && this.data.confirmPassword.length > 9) {
            this.passwordMisMatch = this.data.password != this.data.confirmPassword;
        }
    }

    public resetPassword() {
        this.clearFeedback();

        if (this.isFormValid() && !this.isBlocking() && !this.passwordMisMatch) {
            this.startBlocking();
           

            this.userRepository.resetFostpackPassword(this.data.resetKey, this.data.password)
                .then((result) => {
                    this.data.resetComplete = true;

                    this.setPristine();
                    this.stopBlocking();
                    this.scrollToTop();
                })
                .catch((error) => {
                    this.handleFeedback(error);

                    this.data.password = '';
                    this.data.confirmPassword = '';

                    this.setPristine();
                    this.stopBlocking();
                    this.scrollToTop();
                });
        }
    }
}