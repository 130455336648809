<form #myForm="ngForm" novalidate>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6" *ngIf="!data.mailSent">
            <div class="row">
                <div class="col-md-12">
                    <h1 fp-translate>MyApp.UserPasswordForgot_Title</h1>
                </div>
            </div>
            <div class="form-horizontal">
                <fp-error-display [errors]="errors"
                                  [warnings]="warnings"></fp-error-display>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <span fp-translate>MyApp.UserPasswordForgot_Text</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <fp-label label="Users.User_WindowsLogon"></fp-label>
                </div>
                <div class="col-md-7">
                    <fp-textbox name="Users.User_WindowsLogon"
                                [(ngModel)]="data.windowsLogon"
                                maxlength="100"
                                required="true"></fp-textbox>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <fp-label label="Users.User_Email"></fp-label>
                </div>
                <div class="col-md-7">
                    <fp-textbox name="Users.User_Email"
                                [(ngModel)]="data.email"
                                maxlength="50"
                                required="true"></fp-textbox>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="btn-toolbar">
                        <button class="btn btn-primary" (click)="sendResetEmail()">
                            <fa-icon icon="user"></fa-icon>
                            <span fp-translate>MyApp.UserPasswordReset_Reset</span>
                        </button>
                    </div>     
                </div>
            </div>
        </div>
        <div class="col-md-6" *ngIf="data.mailSent">
            <div class="row success-text">
                <div class="col-md-2">
                    <fa-icon [icon]="['far', 'square-check']" size="5x"></fa-icon>
                </div>
                <div class="col-md-10">
                    <p [innerHtml]="mailSentText"></p>
                </div>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>
</form>


