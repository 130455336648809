<div class="form-group" [ngClass]="{'has-error': model && model.invalid && model.dirty}">
    <table class="table-fixed">
        <tr>
            <td>
                <div>
                    <input type="password"
                           class="form-control k-input k-textbox"
                           [id]="controlId"
                           [name]="controlId + '_input'"
                           [(ngModel)]="value" />
                </div>
            </td>
            <td width="2px">&nbsp;</td>
            <td width="15px">
                <div *ngIf="model && model.invalid && model.dirty" class="tooltip-validation">
                    <fa-icon icon="exclamation-circle"></fa-icon>
                </div>
            </td>
        </tr>
        <tr *ngIf="model && model.invalid && model.dirty">
            <td colspan="3">
                <div>
                    <fp-validation-error [for]="name" [errors]="model?.errors"></fp-validation-error>
                </div>
            </td>
        </tr>
    </table>
</div>