import { Component } from '@angular/core';
import { ConfigurationService } from "../services/configuration.service";
import { LanguageService } from '../services/language.service'
import { Router } from "@angular/router";
import { MainContextService } from "../services/mainContext.service";

@Component({
    //moduleId: module.id,
    selector: "fp-context",
    templateUrl: "./context.component.html"
})

export class ContextComponent {
    
    constructor(public config: ConfigurationService,
        private language: LanguageService,
        public router: Router,
        public mainContextService: MainContextService) {
        this.showTranslation = config.getShowTranslationOption();
    }
    
    public showTranslation: boolean = false;
    
    public isActive(lang: string): string {
        if (lang === this.language.getLanguage()) {
            return 'active';
        }

        return '';
    }

    public changeLanguage(lang: string): void {
        this.language.updateLanguage(lang);
    }

    public translationKeysActive(): string {
        return this.language.getShowTranslationKeys() ? 'active' : '';
    }

    public toggleTranslationKeys(): void {
        this.language.toggleTranslationKeys();
    }
}