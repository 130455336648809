import { Component, Inject, HostListener} from "@angular/core"
import { MainContextService } from "../services/mainContext.service";
import { MainContext } from "../../../ScriptsModels/MainContext";
import * as moment from "moment";

@Component({
    selector: "fp-nav",
    templateUrl: "./nav.component.html"
})

export class NavComponent {

    private _lastValidRefresh: number;

    @HostListener('document:keydown', ['$event'])
    private handleKeyDown(event: KeyboardEvent) {

        if ((event.keyCode == 116 || event.which == 116) && event.ctrlKey == false) {
            event.preventDefault();

            var duration = moment.duration(moment().diff(this._lastValidRefresh));
            var ms = duration.asMilliseconds();

            if (!ms || ms > 500) {
                this._lastValidRefresh = moment.now();
                this.mainContextService.onRefreshRequested.emit(true);
            }
        }
    }

    constructor( @Inject("appName") private appName: string,
        public mainContextService: MainContextService) {
        this.mainContext = mainContextService.currentContext;
    }

    public mainContext: MainContext;
}