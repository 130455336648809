import { Component, Input, Output, OnInit, EventEmitter, Optional, Self } from "@angular/core";
import { ControlValueAccessor, NgModel } from "@angular/forms";

export interface IButtonGroupItemData {
    value: boolean | number;
    name?: string;
    translationKey?: string;
    disabled?: boolean;
}

@Component({
    selector: "fp-buttongroup",
    templateUrl: "./fpButtonGroup.component.html",
    host: { 'class': 'fp-control' }
})
export class FpButtonGroupComponent implements ControlValueAccessor, OnInit {
    public model: NgModel;
    
    @Input() name: string;
    @Input() disabled: boolean;
    @Input() readMode: boolean = false;
    // if stretched true, the button-group will have width of parent (mostly div col-md)
    // if false, the buttons will have the size of the content text (with appropriate padding)
    @Input() stretched: boolean = false;

    @Output() selectionChange: EventEmitter<boolean | number> = new EventEmitter<boolean | number>();

    private innerData: Array<IButtonGroupItemData>;
    private innerValue: boolean | number;
    private controlId: string = "";

    public width = "";

    constructor(
        @Optional() @Self() ngModel: NgModel | null) {
        this.model = ngModel;

        // Set the valueAccessor manually.
        // Before this was done by providing the component as NG_VALUE_ACCESSOR,
        //    but this causes a circular import when injecting NgModel in the constructor.
        if (this.model) {
            this.model.valueAccessor = this;
        }
    }

    onChange(_): void { }

    onTouched(): void { }

    ngOnInit(): void {
        this.controlId = this.name.replace('.', '_');
        this.width = this.stretched ? 'auto' : '';
    }

    @Input() get data(): Array<IButtonGroupItemData> {
        return this.innerData;
    }

    set data(buttons: Array<IButtonGroupItemData>) {
        this.innerData = buttons;

        if (this.innerValue !== null && this.innerValue != undefined) {
            // Check if the collection contains the current selected value
            var possibleValues = this.innerData.map((button) => {
                return button.value;
            });

            if (possibleValues && possibleValues.length > 0) {
                if (possibleValues.indexOf(this.innerValue) === -1) {
                    this.toggleButton(possibleValues[0], true);
                }
            } else {
                this.toggleButton(null, true);
            }
        }
    }

    //get accessor
    get value(): boolean | number {
        if (this.innerValue === null || this.innerValue === undefined) {
            return null;
        }

        return this.innerValue;
    };

    writeValue(value: boolean | number): void {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    private toggleButton(buttonValue: boolean | number, isSelected: boolean = true) {
        // Ignore events from buttons that are deactivated.
        // In our case they are always followed by another button activating
        if (isSelected) {
            if (buttonValue !== this.innerValue) {
                this.innerValue = buttonValue;

                if (this.onChange) {
                    this.onChange(buttonValue);
                }

                this.selectionChange.emit(buttonValue);
            }
        }
    }
    
    private getSelectedButtonGroupItem(): IButtonGroupItemData[] {
        if (this.readMode && this.innerData && this.innerData.length > 0 && (this.innerValue || this.innerValue === false)) {
            var items = this.innerData.filter((item) => {
                return item.value == this.innerValue;
            });

            return items;
        }

        return null;
    }

    private isSelected(button: IButtonGroupItemData): boolean {
        return button.value === this.innerValue;
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
}