import { Injectable } from "@angular/core";
import { ConfigurationService } from "./configuration.service";

/**
 * Log messages to the browser console if available.
 */
@Injectable()
export class LogService {
    constructor(private configuration: ConfigurationService) {
        this.isDebug = configuration.isDebug();
    }

    private isDebug: boolean = true;

    private noop() { }

    /**
     * enable/disable debug logging
     * @param flag 
     */
    public debugEnabled(flag: boolean) {
        this.isDebug = flag;
    }

    /**
     * Write log message to the console.
     * @returns {} 
     */
    get log(): (arg1: any, ...arg2: any[]) => void {
        if (console && this.isDebug) {
            return console.log.bind(window.console);
        } else {
            return this.noop;
        }
    }

    /**
     * Write info message to the console.
     * @returns {} 
     */
    get info(): (arg1: any, ...arg2: any[]) => void {
        if (console && this.isDebug) {
            return console.info.bind(window.console);
        } else {
            return this.noop;
        }
    }

    /**
     * Write warning message to the console.
     * @returns {} 
     */
    get warn(): (arg1: any, ...arg2: any[]) => void {
        if (console && this.isDebug) {
            return console.warn.bind(window.console);
        } else {
            return this.noop;
        }
    }

    /**
     * Write error message to the console.
     * @returns {} 
     */
    get error(): (arg1: any, ...arg2: any[]) => void {
        if (console) {
            return console.error.bind(window.console);
        } else {
            return this.noop;
        }
    }
}