import { Component, ViewChild } from "@angular/core";
import { Params } from "@angular/router";
import { BaseRouteDependencies, BaseRouteDepenciesFactory, baseRouteDeps } from "./base/baseRouteDependencies.provider";
import { BaseRouteComponent } from "./base/baseRoute.component";
import { UserRepository } from "../repositories/user.repository";
import { DataTask } from "../classes/dataTask"
import { ReCaptchaV3Service, RecaptchaComponent } from "ng-recaptcha";

interface IForgotPasswordData {
    windowsLogon: string;
    email: string;
    mailSent: boolean;
}

@Component({
    templateUrl: "./fostpackForgotPassword.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})
export class FostpackForgotPasswordComponent extends BaseRouteComponent<IForgotPasswordData> {

    @ViewChild("invisibleCaptcha", { static: false })
    public invisibleCaptcha?: RecaptchaComponent;

    constructor(baseRouteDeps: BaseRouteDependencies,
        private userRepository: UserRepository) {
        super("FostpackForgotPasswordComponent", baseRouteDeps);
    }

    public mailSentText: string = "";

    protected configure() {
        // Normally called when activating a menu item
        // In this case there is no menu item
        this.baseRouteDeps.mainContextService.refreshBodyOffset();
        this.data = this.getEmptyDetailData();

        this.dataLoadFailed = () => {
        };

        this.dataLoaded = () => {
        };

        this.addDataTask(new DataTask(this.loadMailSentText, this.processMailSentText, { reloadOnCultureChange: true, reload: false }));

        this.processQueryParams();
    }

    protected queryParamsUpdated(params: Params): void {
        this.clearFeedback();
        this.processQueryParams();
    }

    private processQueryParams(): void {
        var lang = <string>this.queryParams["locale"];
        var langChanged = lang && lang.toLowerCase() !== this.baseRouteDeps.languageService.getLanguage();

        if (langChanged) {
            this.baseRouteDeps.languageService.updateLanguage(lang);
        }
    }

    private getEmptyDetailData(): IForgotPasswordData {
        return {
            windowsLogon: '',
            email: '',
            mailSent: false
        };
    }

    private loadMailSentText(resolve: (data: string) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.translateService.get("MyApp.UserPasswordReset_MailSent_Text").subscribe((value) => {
            resolve(value);
        });
    }

    private processMailSentText(data: string) {
        this.mailSentText = data.replace("\r\n", "<br>");
    }

    public sendResetEmail() {
        this.clearFeedback();
        if (!this.noCaptcha) {
            this.invisibleCaptcha.execute();
        }
        else {
            this.sendCreateActiveDirectoryPasswordResetEmailRequest(null);
        }
    }

    public onResolveCaptcha(reCaptchaToken: string) {
        if (reCaptchaToken == null) {
            return;
        }

        this.sendCreateActiveDirectoryPasswordResetEmailRequest(reCaptchaToken);
        this.invisibleCaptcha.reset();
    }

    private sendCreateActiveDirectoryPasswordResetEmailRequest(reCaptchaToken: string) {
        if (this.isFormValid() && !this.isBlocking()) {
            this.startBlocking();

            const languageCode = this.baseRouteDeps.languageService.getLanguage();
            this.userRepository.createActiveDirectoryPasswordResetEmail(this.data.email, languageCode, this.data.windowsLogon, reCaptchaToken)
                .then((result) => {
                    this.data.mailSent = true;

                    this.setPristine();

                    this.stopBlocking();
                    this.scrollToTop();
                })
                .catch((error) => {
                    this.handleFeedback(error);

                    this.setPristine();

                    this.stopBlocking();
                    this.scrollToTop();
                });
        }
    }
}