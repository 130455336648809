import { Component, OnInit, Inject, ViewEncapsulation, ViewChild } from "@angular/core"
import { LanguageService } from "./services/language.service";
import { MainContextService } from "./services/mainContext.service";
import { LeavePageService } from "./services/leavePage.service";
import { FpModalDialogComponent } from "./modules/shared/components/forms/fpModalDialog.component";

@Component({
    //moduleId: module.id.toString(),
    selector: "body",
    templateUrl: "./app.component.html",
    encapsulation: ViewEncapsulation.None,
    host: { '[class.fullScreen]': 'mainContextService.isFullScreen' }
})
export class AppComponent implements OnInit {
    @ViewChild("leavePageDialog", { static: true }) private leavePageDialog: FpModalDialogComponent;

    // test
    constructor(private languageService: LanguageService,
        public mainContextService: MainContextService,
        private leavePageService: LeavePageService,
        @Inject("appName") private appName: string) {
        
    }

    ngOnInit(): void {
        this.languageService.setInitialLanguage();

        this.leavePageService.dialogOpenObs.subscribe(
            () => {
                if (this.leavePageDialog) {
                    this.leavePageDialog.showDialog();
                }
            }
        );
    }

    public leavePageDialogResult(result: boolean): void {
        this.leavePageService.callLeavePageChoice(result);
    }
}

