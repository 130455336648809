import { Injectable, Inject } from '@angular/core';
import { HttpProvider } from "../services/http.provider";

import Users = FostPlus.Olympus.UsersDomain.Clients.Api;
//import AmontDeclarationDomain = FostPlus.Amont.DeclarationDomain.Services.Contract;

export interface IUserRepository {
    checkActivationKeyValidity(activationKey: string): Promise<Users.IActivationKeyValidityCheckResponse>;
    activateUser(request: Users.IUserActivateRequest): Promise<Users.IUserActivateResponse>;
    getUserForRegistration(crmTrackingCode: string): Promise<Users.IUserForRegistrationGetResponse>;
    registerUser(request: Users.IUserForRegistrationRequest): Promise<Users.IUserForRegistrationResponse>;
    checkUsernameAvailability(windowsLogon: string, organisationGroupId): Promise<Users.IUserCheckUsernameAvailabilityAnonymousResponse>;
    UserIAMRegistrationAnonymousGet(organisationIdentificationNumber: string, partyNumber: string): Promise<Users.IUserIAMRegistrationAnonymousGetToTreatResponse>;
    UserIAMRegistrationAnonymousGetWithCaptcha(organisationIdentificationNumber: string, partyNumber: string, reCaptchaToken: string): Promise<Users.IUserIAMRegistrationAnonymousGetToTreatResponse>;
    UserIAMRegistrationAnonymousCreateOrUpdate(request: Users.IUserIAMRegistrationAnonymousCreateOrUpdateRequest): Promise<Users.IUserIAMRegistrationAnonymousCreateOrUpdateResponse>;

    checkResetKeyValidity(resetKey: string): Promise<Users.IResetKeyValidityCheckResponse>;
    resetPassword(request: Users.IUserPasswordResetRequest): Promise<Users.IUserPasswordResetResponse>;
    createUserPasswordResetEmail(email: string, windowsLogon: string): Promise<Users.IUserPasswordResetEmailCreateResponse>;
}

@Injectable()
export class UserRepository implements IUserRepository {
    constructor(private http: HttpProvider,
        @Inject('rootUrl') private rootUrl: string) {
    }

    checkActivationKeyValidity(activationKey: string): Promise<Users.IActivationKeyValidityCheckResponse> {
        return this.http.get<Users.IActivationKeyValidityCheckResponse>(this.rootUrl + "api/users/activationKeyValidityCheck/" + activationKey);
    }

    activateUser(request: Users.IUserActivateRequest): Promise<Users.IUserActivateResponse> {
        var a = this.http.post<Users.IUserActivateResponse>(this.rootUrl + "api/users/activate", request);
        return a;
    }

    getUserForRegistration(crmTrackingCode: string): Promise<Users.IUserForRegistrationGetResponse> {
        var code = encodeURIComponent(crmTrackingCode);
        return this.http.get<Users.IUserForRegistrationGetResponse>(this.rootUrl + "api/users/forRegistration?crmTrackingCode=" + code);
    }

    registerUser(request: Users.IUserForRegistrationRequest): Promise<Users.IUserForRegistrationResponse> {
        var a = this.http.post<Users.IUserForRegistrationResponse>(this.rootUrl + "api/users/register", request);
        return a;
    }

    checkResetKeyValidity(resetKey: string): Promise<Users.IResetKeyValidityCheckResponse> {
        return this.http.get<Users.IResetKeyValidityCheckResponse>(this.rootUrl + "api/users/resetKeyValidityCheck/" + resetKey);
    }

    resetPassword(request: Users.IUserPasswordResetRequest): Promise<Users.IUserPasswordResetResponse> {
        var a = this.http.post<Users.IUserPasswordResetResponse>(this.rootUrl + "api/users/resetPassword", request);
        return a;
    }

    createUserPasswordResetEmail(email: string, windowsLogon: string): Promise<Users.IUserPasswordResetEmailCreateResponse> {
        var request: Users.IUserPasswordResetEmailCreateRequest = {
            windowsLogon: windowsLogon,
            email: email
        };
        var a = this.http.post<Users.IUserPasswordResetResponse>(this.rootUrl + "api/users/createPasswordResetEmail", request);
        return a;
    };

    //OnlineDeclarationRequest(request: AmontDeclarationDomain.Requests.IOnlineDeclarationRequestCreateRequest): Promise<AmontDeclarationDomain.Requests.IOnlineDeclarationRequestCreateResponse> {
    //    var a = this.http.post<AmontDeclarationDomain.Requests.IOnlineDeclarationRequestCreateResponse>(this.rootUrl + "api/onlineDeclarationRequest/OnlineDeclarationRequestCreate", request);
    //    return a;
    //};

    createActiveDirectoryPasswordResetEmail(email: string, languageCode: string, windowsLogon: string, reCaptchaToken : string): Promise<Users.IActiveDirectoryPasswordResetEmailCreateResponse> {
        var request: Users.IActiveDirectoryPasswordResetEmailCreateRequest = {
            email, windowsLogon, languageCode, reCaptchaToken
        };

        var a = this.http.post<Users.IActiveDirectoryPasswordResetEmailCreateResponse>(this.rootUrl + "api/users/fostpack1/createPasswordResetEmail", request);
        return a;

    }

    validateFostpackResetKey(resetKey: string) {
        var a = this.http.get<Users.IActiveDirectoryResetKeyValidityCheckResponse>(this.rootUrl + `api/users/fostpack1/resetKeyValidityCheck/${resetKey}`);
        return a;
    }

    resetFostpackPassword(resetKey: string, password: string) {
        var request: Users.IActiveDirectoryPasswordResetRequest = {
            resetKey,
            password
        }

        var a = this.http.post<Users.IActiveDirectoryPasswordResetResponse>(this.rootUrl + 'api/users/fostpack1/resetPassword', request);
        return a;
    }

    createFostpackForgotUsernameEmail(email: string, languageCode: string, reCaptchaToken: string) {
        var request: Users.IActiveDirectoryForgotUsernameEmailCreateRequest = {
            email,
            languageCode,
            reCaptchaToken
        };

        var a = this.http.post<Users.IActiveDirectoryForgotUsernameEmailCreateResponse>(this.rootUrl + 'api/users/fostpack1/createForgotUsernameEmail', request);
        return a;
    }

    userForgotUsernameEmailCreate(email: string, languageCode: string, reCaptchaToken: string) {
        var request: Users.IUserForgotUsernameEmailCreateRequest = {
            email,
            languageCode,
            reCaptchaToken
        };

        var a = this.http.post<Users.IUserForgotUsernameEmailCreateResponse>(this.rootUrl + 'api/users/userForgotUsernameEmailCreate', request);
        return a;
    }

    checkUsernameAvailability(windowsLogon: string): Promise<Users.IUserCheckUsernameAvailabilityAnonymousResponse> {
        var request: Users.IUserCheckUsernameAvailabilityAnonymousRequest = {
            windowsLogon: windowsLogon,
        };
        var url = this.rootUrl + 'api/users/checkUsernameAvailability';
        var a = this.http.post<Users.IUserCheckUsernameAvailabilityAnonymousResponse>(url, request);
        return a;
    };

    UserIAMRegistrationAnonymousCreateOrUpdate = (request: Users.IUserIAMRegistrationAnonymousCreateOrUpdateRequest): Promise<Users.IUserIAMRegistrationAnonymousCreateOrUpdateResponse> => {
        var a = this.http.post<Users.IUserIAMRegistrationAnonymousCreateOrUpdateResponse>(this.rootUrl + "api/userIAMRegistrations/createOrUpdate", request);
        return a;
    }

    UserIAMRegistrationAnonymousGet = (organisationIdentificationNumber: string, partyNumber: string): Promise<Users.IUserIAMRegistrationAnonymousGetToTreatResponse> => {
        var url = "api/userIAMRegistrations/retrieve?organisationIdentificationNumber=" + organisationIdentificationNumber + "&partyNumber=" + partyNumber;
        var a = this.http.get<Users.IUserIAMRegistrationAnonymousGetToTreatResponse>(this.rootUrl + url);
        return a;
    }

    UserIAMRegistrationAnonymousGetWithCaptcha = (organisationIdentificationNumber: string, partyNumber: string, reCaptchaToken: string): Promise<Users.IUserIAMRegistrationAnonymousGetToTreatResponse> => {
        var url = "api/userIAMRegistrations/retrieveWithCaptcha?organisationIdentificationNumber=" + organisationIdentificationNumber;
        url += "&reCaptchaToken=" + reCaptchaToken;
        if (partyNumber) {
            url += "&partyNumber=" + partyNumber;
        }
        var a = this.http.get<Users.IUserIAMRegistrationAnonymousGetToTreatResponse>(this.rootUrl + url);
        return a;
    }
}