import { Component } from "@angular/core";
import { Params } from "@angular/router";
import { BaseRouteDependencies, BaseRouteDepenciesFactory, baseRouteDeps } from "./base/baseRouteDependencies.provider";
import { BaseRouteComponent } from "./base/baseRoute.component";
import { UserRepository } from "../repositories/user.repository";
import { DataTask } from "../classes/dataTask"

import UsersDomain = FostPlus.Olympus.UsersDomain.Clients.Api;


interface IActivateUserData {
    activationKey: string;
    activationKeyValid: boolean;
    password: string;
    confirmPassword: string;
    activationComplete: boolean;
}

@Component({
    templateUrl: "./activateUser.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})
export class ActivateUserComponent extends BaseRouteComponent<IActivateUserData> {
    constructor(baseRouteDeps: BaseRouteDependencies,
        private userRepository: UserRepository ) {
        super("ActivateUserComponent", baseRouteDeps);
    }

    public activationSuccessText: string = "";
    public passwordMisMatch = false;

    protected configure() {
        // Normally called when activating a menu item
        // In this case there is no menu item
        this.baseRouteDeps.mainContextService.refreshBodyOffset();
        this.data = this.getEmptyDetailData();

        this.dataLoadFailed = () => {
        };

        this.dataLoaded = () => {
            this.passwordMisMatchCheck();
        };

        this.addDataTask(new DataTask(this.checkActivationKeyValidity, this.processActivationKeyValidity, { reloadOnCultureChange: true, reload: true }));
        this.addDataTask(new DataTask(this.loadSuccessText, this.processSuccessText, { reloadOnCultureChange: true, reload: false }));

        this.processQueryParams();
    }

    protected queryParamsUpdated(params: Params): void {
        var reload = this.processQueryParams();
        this.clearFeedback();

        if (reload) {
            this.reload();
        }
    }

    private processQueryParams(): boolean {
        var key = <string>this.queryParams["key"];
        var lang = <string>this.queryParams["locale"];

        var langChanged = lang && lang.toLowerCase() !== this.baseRouteDeps.languageService.getLanguage();
        var keyChanged = key && (key.toLowerCase() !== this.data.activationKey);

        if (keyChanged) {
            this.data = this.getEmptyDetailData();
            this.data.activationKey = key.toLowerCase();
        }

        if (langChanged) {
            this.baseRouteDeps.languageService.updateLanguage(lang);
        }

        // do not reload when both key and lang changed
        // changing lang will also re-check if key is valid
        return keyChanged && !langChanged;
    }

    private getEmptyDetailData(): IActivateUserData {
        return {
            activationKey: null,
            activationKeyValid: false,
            password: '',
            confirmPassword: '',
            activationComplete: false
        };
    }

    private checkActivationKeyValidity(resolve: (data: UsersDomain.IActivationKeyValidityCheckResponse) => void, reject: (reason: any) => void) {
        
        this.userRepository.checkActivationKeyValidity(this.data.activationKey).then((result) => {
            resolve(result);
        }).catch((error) => {
            reject(error);
        });
    }

    private processActivationKeyValidity(data: UsersDomain.IActivationKeyValidityCheckResponse) {
        if (data.validationErrors && data.validationErrors.length > 0) {
            this.data.activationKeyValid = false;

            this.handleValidationErrors(data);
        } else {
            this.data.activationKeyValid = true;
        }
    }

    private loadSuccessText(resolve: (data: string) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.translateService.get("MyApp.UserActivation_Success_Text").subscribe((value) => {
            resolve(value);
        });
    }

    private processSuccessText(data: string) {
        var myFostUri = this.baseRouteDeps.configuration.getMyFostBaseUri();
        var text = data.replace("{MyFostUrl}", "<a href=\"" + myFostUri + "\">" + myFostUri + "</a>");
        text = text.replace("\r\n", "<br><br>");
        this.activationSuccessText = text;
    }

    private passwordMisMatchCheck(): void {
        this.passwordMisMatch = false;

        if (this.data.password.length > 9 && this.data.confirmPassword.length > 9) {
            this.passwordMisMatch = this.data.password != this.data.confirmPassword;
        }
    }

    public activateUser() {
        this.clearFeedback();

        this.passwordMisMatchCheck();

        if (this.isFormValid() && !this.isBlocking() && !this.passwordMisMatch) {
            this.startBlocking();

            var request: UsersDomain.IUserActivateRequest = {
                activationKey: this.data.activationKey,
                password: this.data.password
            }

            this.userRepository.activateUser(request)
                .then((result) => {
                    this.data.activationComplete = true;

                    this.setPristine();

                    this.stopBlocking();
                    this.scrollToTop();
                })
                .catch((error) => {
                    this.handleFeedback(error, request);

                    this.data.password = '';
                    this.data.confirmPassword = '';

                    this.setPristine();

                    this.stopBlocking();
                    this.scrollToTop();
                });
        }
    }

    
}