<!-- In this app we don't really have a user (context), but we want to reuse the css of the other apps (Partner, MyFost, ...)
        => so we also use the context-user class-->
<!-- User context -->
<div class="context-user">
    <!-- Welcome -->
    <span fp-translate>MyApp.Welcome</span>
    <!-- Fullscreen icon -->
    <div class="full-screen-icon" (click)="mainContextService.toggleFullScreen()">
        <fa-icon icon="expand" class="hand" *ngIf="!mainContextService.isFullScreen" size="1x"></fa-icon>
        <fa-icon icon="compress" class="hand" *ngIf="mainContextService.isFullScreen" size="1x"></fa-icon>
    </div>
</div>
<!-- Language selection context -->
<ul class="context-language">
    <li><span [ngClass]="isActive('nl')" (click)="changeLanguage('nl')">NL</span></li>
    <li><span class="" [ngClass]="isActive('fr')" (click)="changeLanguage('fr')">FR</span></li>
    <li><span class="" [ngClass]="isActive('en')" (click)="changeLanguage('en')">EN</span></li>
    <li><span *ngIf="showTranslation" class="noOutline" [ngClass]="translationKeysActive()" (click)="toggleTranslationKeys()" tabindex="-1">VERTAAL</span></li>
</ul>
