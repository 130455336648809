import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpHeaders } from "@angular/common/http";
import { ConfigurationService } from "../services/configuration.service";

import { Observable } from 'rxjs';

@Injectable()
export class InjectHeadersInterceptor implements HttpInterceptor {

    constructor(private config: ConfigurationService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let headers = new HttpHeaders();

        headers = headers.append("X-Requested-With", "XMLHttpRequest");
        headers = headers.append("Content-Type", "application/json");

        if (this.config.getRequestLanguage()) {
            headers = headers.append("Accept-Language", this.config.getRequestLanguage());
        }

        if (req.method !== "GET" && this.config.getAntiForgeryToken()) {
            headers = headers.append("__RequestVerificationToken", this.config.getAntiForgeryToken());
        }

        const request = req.clone({
            headers: headers,
            responseType: 'text'
        });

        return next.handle(request);
    }
}