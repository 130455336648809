<form #myForm="ngForm" novalidate>
    <fp-error-display [errors]="errors"
                      [warnings]="warnings"></fp-error-display>

    <div class="row">
        <div class="col-lg-12">
            <h4 class="panel-title" fp-translate>
                MyApp.UserIAMRegistrationAnonymous_NewIAMTitle
            </h4>
        </div>
    </div>
    <div class="row" *ngIf="showRequestSentInfo" style="margin-bottom: 5px;">
        <div class="col-md-12">
            <span class="bg-info">
                <fa-icon icon="info-circle"></fa-icon>
                &nbsp;
                <span fp-translate>MyApp.UserIAMRegistrationAnonymous_RequestSent</span>
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <p fp-translate>
                MyApp.UserIAMRegistrationAnonymous_NewIAMInfo1
            </p>
            <p [innerHTML]="'MyApp.UserIAMRegistrationAnonymous_NewIAMInfo2' | translate">
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-md-3">
            <fp-label label="MyApp.UserIAMRegistrationAnonymous_OrganisationIdentificationNumber"></fp-label>
        </div>
        <div class="col-md-2">
            <fp-textbox name="MyApp.UserIAMRegistrationAnonymous_OrganisationIdentificationNumber"
                        [(ngModel)]="data.organisationIdentificationNumberUnformatted"
                        (valueChange)="resetShowCreateIAM()"
                        required="true"></fp-textbox>
        </div>
        <div class="col-md-7" *ngIf="activeIAMEmail">
            <div class="alert alert-danger">
                <p>{{ 'MyApp.UserIAMRegistrationAnonymous_ExistingIAM' | translate:{ iamEmail: activeIAMEmail } }}</p>
            </div>
        </div>
    </div>

    <div *ngIf="showPartyNumber">
        <div class="row">
            <div class="col-md-3">
                <fp-label label="MyApp.UserIAMRegistrationAnonymous_PartyNumber"></fp-label>
            </div>
            <div class="col-md-2">
                <fp-textbox name="MyApp.UserIAMRegistrationAnonymous_PartyNumber"
                            [(ngModel)]="data.partyNumber"
                            (valueChange)="resetShowCreateIAM()"
                            required="true"></fp-textbox>
            </div>
            <div class="col-md-7" *ngIf="unknownPartyId">
                <div class="alert alert-danger">
                    <p fp-translate>MyApp.UserIAMRegistrationAnonymous_UknownOrgPartyCombo</p>
                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-md-10">
            <div class="btn-toolbar">
                <button class="btn btn-primary" (click)="submit()">
                    <fa-icon icon="download"></fa-icon>
                    <span fp-translate>MyApp.Next</span>
                </button>
            </div>
        </div>
    </div>

    <!--New User-->
    <ng-container *ngIf="showCreateIAM">
        <div class="row">
            <div class="col-lg-12">
                <h4 class="panel-title" fp-translate>
                    MyApp.UserIAMRegistrationAnonymous_NewUser
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <fp-label label="Users.User_LuGenderId"></fp-label>
            </div>
            <div class="col-md-2">
                <fp-dropdown name="Users.User_LuGenderId"
                             [(ngModel)]="data.luGenderId"
                             [(data)]="luGenders"
                             valueField="id"
                             textField="salutationShortName"
                             required="true"
                             [showDefaultItem]="true"></fp-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <fp-label label="Users.User_FirstName"></fp-label>
            </div>
            <div class="col-md-2">
                <fp-textbox name="Users.User_FirstName"
                            [(ngModel)]="data.firstName"
                            required="true"
                            maxlength="256"></fp-textbox>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <fp-label label="Users.User_LastName"></fp-label>
            </div>
            <div class="col-md-2">
                <fp-textbox name="Users.User_LastName"
                            [(ngModel)]="data.lastName"
                            required="true"
                            maxlength="256"></fp-textbox>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <fp-label label="Users.User_LuLanguageId"></fp-label>
            </div>
            <div class="col-md-2">
                <fp-dropdown name="Users.User_LuLanguageId"
                             [(ngModel)]="data.luLanguageId"
                             [(data)]="luLanguages"
                             valueField="id"
                             textField="name"
                             required="true"
                             [showDefaultItem]="true"></fp-dropdown>
            </div>
        </div>
        <ng-container *ngIf="showUserName">
            <div class="row">
                <div class="col-md-2">
                    <fp-label label="Users.User_WindowsLogon"></fp-label>
                </div>
                <div>
                    <div class="col-md-6">
                        <fp-textbox name="Users.User_WindowsLogon"
                                    [(ngModel)]="data.userName"
                                    required="true"
                                    maxlength="254"></fp-textbox>
                    </div>
                    <div class="col-md-4">
                        <button class="btn btn-outline" type="button" (click)="checkUsernameAlreadyExists()">
                            <fa-icon icon="exclamation-circle" size="lg"></fa-icon>
                            <span fp-translate>MyApp.DoesWindowsLogonAlreadyExist</span>
                        </button>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="row">
            <div class="col-md-2">
                <fp-label label="Users.User_Email"></fp-label>
            </div>
            <div class="col-md-4">
                <fp-textbox name="Users.User_Email"
                            [(ngModel)]="data.email"
                            required="true"
                            maxlength="50"></fp-textbox>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <fp-checkbox name="MyApp.UserIAMRegistrationAnonymous_hasAgreedToTermsOfUse"
                             [(ngModel)]="data.hasAgreedToTermsOfUse"
                             #hasAgreedToTermsOfUse="ngModel"
                             required="true"></fp-checkbox>
                <a [href]="linkToTermsOfUseNl" target="_blank">NL</a>
                <a [href]="linkToTermsOfUseFr" target="_blank">FR</a>
                <a [href]="linkToTermsOfUseEn" target="_blank">EN</a>

                <div *ngIf="hasAgreedToTermsOfUse.dirty && !hasAgreedToTermsOfUse.value" class="validation-errors">
                    <span fp-translate [translateParams]="{ fieldName: 'MyApp.UserIAMRegistrationAnonymous_hasAgreedToTermsOfUse' | translate }">MyApp.IsRequired</span>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <fp-checkbox name="MyApp.UserIAMRegistrationAnonymous_hasDeclaredNoIAM"
                             [(ngModel)]="data.hasDeclaredNoIAM"
                             #hasDeclaredNoIAM="ngModel"
                             required="true"></fp-checkbox>

                <div *ngIf="hasDeclaredNoIAM.dirty && !hasDeclaredNoIAM.value" class="validation-errors">
                    <span fp-translate [translateParams]="{ fieldName: 'MyApp.UserIAMRegistrationAnonymous_hasDeclaredNoIAM' | translate }">MyApp.IsRequired</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-10">
                <div class="btn-toolbar">
                    <button class="btn btn-primary" type="submit" (click)="save()">
                        <fa-icon icon="download"></fa-icon>
                        <span fp-translate>MyApp.Save</span>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>

    <re-captcha size="invisible" *ngIf="!noCaptcha" #invisibleCaptcha (resolved)="onResolveCaptcha($event)" name="captcha" required></re-captcha>
</form>
