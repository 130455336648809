import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ConfigurationService } from "./configuration.service";

@Injectable()
export class HttpProvider {

    public token: string;

    constructor(private httpClient: HttpClient,
        private config: ConfigurationService) { }

    get<T>(url: string): Promise<T> {
        return this.httpClient.get<T>(url).toPromise();
    }

    getAsObservable<T>(url: string): Observable<T> {
        return this.httpClient.get<T>(url);
    }

    post<T>(url: string, body: any): Promise<T> {
        return this.httpClient.post<T>(url, body).toPromise();
    }

    put<T>(url: string, body: any): Promise<T> {
        return this.httpClient.put<T>(url, body).toPromise();
    }

    delete<T>(url: string): Promise<T> {
        return this.httpClient.delete<T>(url).toPromise();
    }

    patch<T>(url: string, body: any): Promise<T> {
        return this.httpClient.patch<T>(url, body).toPromise();
    }

    head<T>(url: string): Promise<T> {
        return this.httpClient.head<T>(url).toPromise();
    }

    options<T>(url: string): Promise<T> {
        return this.httpClient.options<T>(url).toPromise();
    }
}