import { Component, ViewChild } from "@angular/core";
import { Params } from "@angular/router";
import { BaseRouteDependencies, BaseRouteDepenciesFactory, baseRouteDeps } from "./base/baseRouteDependencies.provider";
import { BaseRouteComponent } from "./base/baseRoute.component";
import { UserRepository } from "../repositories/user.repository";
import { DataTask } from "../classes/dataTask"
import { RecaptchaComponent } from "ng-recaptcha";

interface IForGotUsernameData {
    email: string;
    mailSent: boolean;
}

@Component({
    templateUrl: "./fostpackForgotUsername.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})
export class FostpackForgotUsernameComponent extends BaseRouteComponent<IForGotUsernameData> {

    @ViewChild("invisibleCaptcha", { static: false })
    public invisibleCaptcha?: RecaptchaComponent;

    constructor(baseRouteDeps: BaseRouteDependencies,
        private userRepository: UserRepository) {
        super("FostpackForgotPasswordComponent", baseRouteDeps);
    }


    public mailSentText: string = "";

    protected configure() {
        // Normally called when activating a menu item
        // In this case there is no menu item
        this.baseRouteDeps.mainContextService.refreshBodyOffset();
        this.data = this.getEmptyDetailData();

    }

    private getEmptyDetailData(): IForGotUsernameData {
        return {
            email: '',
            mailSent: false
        };
    }

    private loadMailSentText(resolve: (data: string) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.translateService.get("MyApp.UserPasswordReset_MailSent_Text").subscribe((value) => {
            resolve(value);
        });
    }

    private processMailSentText(data: string) {
        this.mailSentText = data.replace("\r\n", "<br>");
    }

    public submitForm() {
        this.clearFeedback();
        if (!this.noCaptcha) {
            this.invisibleCaptcha.execute();
        }
        else {
            this.sendCreateFostpackForgotUsernameEmailRequest(null);
        }
    }

    public onResolveCaptcha(reCaptchaToken: string) {
        if (reCaptchaToken == null) {
            return;
        }

        this.sendCreateFostpackForgotUsernameEmailRequest(reCaptchaToken);
        this.invisibleCaptcha.reset();
    }

    private sendCreateFostpackForgotUsernameEmailRequest(reCaptchaToken: string) {
        if (this.isFormValid() && !this.isBlocking()) {

            this.startBlocking();

            const languageCode = this.baseRouteDeps.languageService.getLanguage();
            this.userRepository.createFostpackForgotUsernameEmail(this.data.email, languageCode, reCaptchaToken)
                .then((result) => {
                    this.data.mailSent = true;

                    this.setPristine();

                    this.stopBlocking();
                    this.scrollToTop();
                    this.invisibleCaptcha.reset();
                })
                .catch((error) => {
                    this.handleFeedback(error);

                    this.setPristine();

                    this.stopBlocking();
                    this.scrollToTop();
                    this.invisibleCaptcha.reset();
                });
        }
    }
}