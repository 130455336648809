export enum MenuVisibility {
    Visible = 0,
    //OnlyForInternalUsers = 1,
    Hidden = 2
}

export abstract class MenuItem {
    constructor(public name: string, public translationKey: string, public isAdminMenuItem: boolean = false, public visibility: MenuVisibility = MenuVisibility.Visible ) {
        this.isActive = false;

        this.menuItems = [];
    }

    public isActive: boolean;
    public parent: MenuItem;
    private menuItems: Array<MenuItem>;

    public addSubMenuItem(item: MenuItem): void {
        item.parent = this;
        this.menuItems.push(item);
    }

    public getSubMenuItems(): Array<MenuItem> {
        return this.menuItems;
    }
}

export class UrlMenuItem extends MenuItem {
    constructor(name: string, translationKey: string, public url: string, isAdminMenuItem: boolean = false, visibility: MenuVisibility = MenuVisibility.Visible) {
        super(name, translationKey, isAdminMenuItem, visibility);

        // parameters will be resolved by the MenuService based on the mainContext
        //this.urlWithParameters = this.url;
    }
    
    //public urlWithParameters: string;
}

export class RouteMenuItem extends MenuItem {
    constructor(name: string, translationKey: string, public route: string, public params: Array<string> = [], isAdminMenuItem: boolean = false, visibility: MenuVisibility = MenuVisibility.Visible) {
        super(name, translationKey, isAdminMenuItem, visibility);
    }
}



export class ActivateMenuOptions {
    constructor(public name: string, showSubMenu?: boolean) {
        if (showSubMenu == null) {
            showSubMenu = false;
        }

        this.showSubMenu = showSubMenu;
    }

    public showSubMenu: boolean;
}