<form #myForm="ngForm" novalidate>
    <div class="form-horizontal">
        <!--Backend error messages-->
        <fp-error-display [errors]="errors"
                          [warnings]="warnings"></fp-error-display>
    </div>
    <div class="row" *ngIf="data.resetKeyValid">
        <div class="col-md-3"></div>
        <div class="col-md-6" *ngIf="!data.resetComplete">
            <div class="row">
                <div class="col-md-12">
                    <h1 fp-translate>MyApp.UserPasswordReset_Title</h1>
                </div>
            </div>
            <div class="row" *ngIf="passwordMisMatch">
                <!--Frontend password validation error message-->
                <div class="col-md-10">
                    <div class="alert alert-danger" role="alert">
                        <fa-icon icon="exclamation-circle"></fa-icon>
                        <span fp-translate>Validation.PasswordConfirmationDoesNotMatch</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <fp-label label="MyApp.UserPasswordReset_Password"></fp-label>
                </div>
                <div class="col-md-7">
                    <fp-password name="MyApp.UserPasswordReset_Password"
                                 [(ngModel)]="data.password"
                                 (valueChange)="passwordChange()"
                                 minlength="10"
                                 required="true"></fp-password>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <fp-label label="MyApp.UserPasswordReset_ConfirmPassword"></fp-label>
                </div>
                <div class="col-md-7">
                    <fp-password name="MyApp.UserPasswordReset_ConfirmPassword"
                                 [(ngModel)]="data.confirmPassword"
                                 (valueChange)="passwordChange()"
                                 minlength="10"
                                 required="true"></fp-password>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="btn-toolbar">
                        <button class="btn btn-primary" (click)="resetPassword()">
                            <fa-icon icon="user"></fa-icon>
                            <span fp-translate>MyApp.UserPasswordReset_Reset</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6" *ngIf="data.resetComplete">
            <div class="row success-text">
                <div class="col-md-2">
                    <fa-icon [icon]="['far', 'square-check']" size="5x"></fa-icon>
                </div>
                <div class="col-md-10">
                    <p [innerHtml]="resetSuccessText"></p>
                </div>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>
    <div class="row" *ngIf="isDebug">
        <div class="col-md-12">
            &nbsp;
        </div>
    </div>
    <fpDebugDisplay [isDebug]="isDebug">
        passwordMisMatch: {{passwordMisMatch}}
        <br />
        data.resetKey: {{data.resetKey}}
        <br />
        data.resetKeyValid: {{data.resetKeyValid}}
        <br />
        data.password: {{data.password}}
        <br />
        data.confirmPassword: {{data.confirmPassword}}
        <br />
        data.resetComplete: {{data.resetComplete}}
        <br />
        resetSuccessText: {{resetSuccessText}}
    </fpDebugDisplay>
</form>
