import { Injectable, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ConfigurationService } from "./configuration.service";
import { AnalyticsService } from "./analytics.service";

export interface IShowtranslationKeyChangeEvent {
    showtranslationKeys: boolean;
}

export interface ICultureChangedEvent {
    language: string;
    locale: string;
}

@Injectable()
export class LanguageService {
    private currentLanguage: string;
    private currentLocale: string;
    private showTranslationKeys: boolean = false;

    public onShowTranslationChange: EventEmitter<IShowtranslationKeyChangeEvent> = new EventEmitter<IShowtranslationKeyChangeEvent>();

    public onCultureChange: EventEmitter<ICultureChangedEvent> = new EventEmitter<ICultureChangedEvent>();

    constructor(private translate: TranslateService,
        private config: ConfigurationService,
        private analyticsService: AnalyticsService) {
    }

    public getLanguage(): string {
        return this.currentLanguage;
    };

    public getLocale(): string {
        if (this.currentLocale) {
            return this.currentLocale;
        }

        return null;
    }

    public getShowTranslationKeys(): boolean {
        return this.showTranslationKeys;
    }

    public setInitialLanguage() {
        let lang = this.loadFromLocalStorage();

        if (!lang) {
            lang = this.config.getInitialLanguage();

            if (!lang) {
                lang = "nl";
            }
        }

        this.updateLanguage(lang);
    }

    public updateLanguage(language: string) {

        if (this.currentLanguage !== language) {
            this.currentLanguage = language;
            this.config.setRequestLanguage(language);
            
            // tell angular-translate to use the language
            //this.translate.setDefaultLang("nl");
            this.translate.use(language);
            // use local storage instead
            this.saveToLocalStorage(language);
            
            switch (language) {
                case "nl":
                    this.currentLocale = "nl-BE";
                    break;
                case "fr":
                    this.currentLocale = "fr-BE";
                    break;
                default:
                    this.currentLocale = "en-US";
            }

            this.analyticsService.logEvent('languageChanged', { locale: language });
            this.onCultureChange.emit({
                language: this.currentLanguage,
                locale: this.currentLocale
            });
        }

        
    };

    public toggleTranslationKeys() {
        this.showTranslationKeys = !this.showTranslationKeys;
        this.onShowTranslationChange.emit({ showtranslationKeys: this.showTranslationKeys });
    }

    private loadFromLocalStorage(): string {
        let lang: string;
        try {
            lang = localStorage.getItem("currentLanguage");
        } catch (err) {
            this.analyticsService.trackException(err);
        }

        return lang;
    }

    private saveToLocalStorage(lang: string) {
        try {
            localStorage.setItem("currentLanguage", lang);
        } catch (err) {
            this.analyticsService.trackException(err);
        }
    }
}