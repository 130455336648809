import { Injectable } from "@angular/core";
import { MenuItem, RouteMenuItem, UrlMenuItem, ActivateMenuOptions, MenuVisibility } from "../../../ScriptsModels/MenuItem";
import { MainContextService } from "./mainContext.service";
import { ConfigurationService } from "./configuration.service";
import { BehaviorSubject, Observable} from "rxjs";

export interface ISubMenuVisibilityChangeEvent {
    subMenuVisisble: boolean;
}

@Injectable()
export class MenuService {

    constructor(private mainContextService: MainContextService,
        private configurationService: ConfigurationService
    ) {
        this.menuItemsCollection = new Array<MenuItem>();

        //var dashboardMenu = new RouteMenuItem("nmuDashboard", "MyApp.Dashboard", "/dashboard");
        //this.menuItemsCollection.push(dashboardMenu);

        //var helpMenu = new UrlMenuItem("mnuHelp", "MyApp.Help", "https://fostplus.timelapsehc.com", true);
        //this.menuItemsCollection.push(helpMenu);

        this.activeMainMenu = null;
    }

    private readonly menuItemsCollection: Array<MenuItem>;
    private usersMenu: UrlMenuItem;

    private activeMainMenu: MenuItem;

    private menuItemsSubject = new BehaviorSubject<Array<MenuItem>>([]);
    private adminMenuItemsSubject = new BehaviorSubject<Array<MenuItem>>([]);
    private subMenuItemsSubject = new BehaviorSubject<Array<MenuItem>>([]);
    private subMenuVisibleSubject = new BehaviorSubject<boolean>(false);

    get menuItems(): Observable<Array<MenuItem>> {
        return this.menuItemsSubject.asObservable();
    }

    get adminMenuItems(): Observable<Array<MenuItem>> {
        return this.adminMenuItemsSubject.asObservable();
    }

    get subMenuItems(): Observable<Array<MenuItem>> {
        return this.subMenuItemsSubject.asObservable();
    }
    
    get isSubMenuVisible(): Observable<boolean> {
        return this.subMenuVisibleSubject.asObservable();
    }

    public activate(options: ActivateMenuOptions) {
        this.activateMenuItem(options.name, options.showSubMenu);
    }

    public activateMenuItem(name: string, showSubMenu: boolean = false): void {
        // find menu to activate ( and de-activate other ones )
        var mainMenuItemToActivate: MenuItem = null;
        this.menuItemsCollection.forEach((mainMenuItem) => {
            var mustActivateMainMenu = false;
            if (mainMenuItem.name === name) {
                mustActivateMainMenu = true;
            } else {
                mainMenuItem.getSubMenuItems().forEach((subMenuItem) => {
                    if (subMenuItem.name === name) {
                        subMenuItem.isActive = true;
                        mustActivateMainMenu = true;
                    } else {
                        subMenuItem.isActive = false;
                    }
                });
            }

            if (mustActivateMainMenu) {
                mainMenuItemToActivate = mainMenuItem;
            } else {
                mainMenuItem.isActive = false;
            }
        });

        // found the menu to activate
        if (mainMenuItemToActivate) {
            mainMenuItemToActivate.isActive = true;
            
            this.activeMainMenu = mainMenuItemToActivate;
        } else {
            this.activeMainMenu = null;
        }

        this.mainContextService.refreshBodyOffset();

        this.refreshMenuItems(showSubMenu);
    }

    private refreshMenuItems(showSubMenu: boolean) {
        var visibleItems: MenuItem[];


        visibleItems = this.menuItemsCollection.filter((menuItem) => {
            return menuItem.visibility === MenuVisibility.Visible;
        });

        // Left menu
        var menuItems = visibleItems.filter((menuItems) => {
            return !menuItems.isAdminMenuItem;
        });

        this.menuItemsSubject.next(menuItems);

        // Right menu
        var adminItems = visibleItems.filter((item) => {
            return item.isAdminMenuItem;
        });

        this.adminMenuItemsSubject.next(adminItems);

        var subMenuItems: Array<MenuItem> = null;
        if (this.activeMainMenu && showSubMenu) {
            subMenuItems = this.activeMainMenu.getSubMenuItems().filter((menuItem) => {
                return menuItem.visibility === MenuVisibility.Visible;
            });
        }

        if (subMenuItems && subMenuItems.length > 0) {
            this.subMenuItemsSubject.next(subMenuItems);
            this.subMenuVisibleSubject.next(true);
        } else {
            this.subMenuItemsSubject.next([]);
            this.subMenuVisibleSubject.next(false);
        }
    }

    public resolveUrl(item: UrlMenuItem): string {
        var urlWithParam: string = item.url;

        if (urlWithParam && urlWithParam.length > 0) {
            // get all url parameters
            let urlParams = urlWithParam.split("/").filter((param: string) => {
                return param.startsWith(":");
            }).map((param: string) => {
                return param.substring(1);
            });

            if (urlParams.length > 0) {
                urlParams.forEach((param: string) => {
                    if (this.mainContextService.currentContext[param]) {
                        urlWithParam = urlWithParam
                            .replace(":" + param, this.mainContextService.currentContext[param]);
                    }
                });
            }
        }

        return urlWithParam;
    }

    public resolveRouteParams(item: RouteMenuItem): Object {
        var params: Object = {};

        item.params.forEach((param: string) => {
            params[param] = this.mainContextService.currentContext[param]
        });

        return params;
    }

    public resolveRoute(item: RouteMenuItem): Array<any> {
        var route: Array<any> = [];

        if (item.route && item.route.length > 0) {
            // get all url parameters
            let routeSegments = item.route.split("/");

            if (routeSegments.length > 0) {
                routeSegments.forEach((segment: string) => {
                    if (segment.startsWith(":") && this.mainContextService.currentContext[segment.substring(1)]) {
                        route.push(this.mainContextService.currentContext[segment.substring(1)]);
                    } else {
                        route.push(segment);
                    }
                });
            }
        }

        return route;
    }
}
