<form #myForm="ngForm" novalidate>
    <div class="form-horizontal">
        <!--Backend error messages-->
        <fp-error-display [errors]="errors"
                          [warnings]="warnings"></fp-error-display>
    </div>
    <div class="row" *ngIf="data.activationKeyValid">
        <div class="col-md-3"></div>
        <div class="col-md-6" *ngIf="!data.activationComplete">
            <div class="row">
                <div class="col-md-12">
                    <h1 fp-translate>MyApp.UserActivation_Title</h1>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <span style="white-space: pre-line" fp-translate>
                        MyApp.UserActivation_Text
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    &nbsp;
                </div>
            </div>
            <div class="row" *ngIf="passwordMisMatch">
                <!--Frontend password validation error message-->
                <div class="col-md-12">
                    <div class="alert alert-danger" role="alert">
                        <fa-icon icon="exclamation-circle"></fa-icon>
                        <span fp-translate>Validation.PasswordConfirmationDoesNotMatch</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5">
                    <fp-label label="MyApp.UserActivation_Label_Password"></fp-label>
                </div>
                <div class="col-md-7">
                    <fp-password name="MyApp.UserActivation_Password"
                                 [(ngModel)]="data.password"
                                 minlength="10"
                                 required="true"></fp-password>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5">
                    <fp-label label="MyApp.UserActivation_Label_ConfirmPassword"></fp-label>
                </div>
                <div class="col-md-7">
                    <fp-password name="MyApp.UserActivation_Label_ConfirmPassword"
                                 [(ngModel)]="data.confirmPassword"
                                 minlength="10"
                                 required="true"></fp-password>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="btn-toolbar">
                        <button class="btn btn-primary" (click)="activateUser()">
                            <fa-icon icon="user"></fa-icon>
                            <span fp-translate>MyApp.UserActivation__Label_ActivateUser</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6" *ngIf="data.activationComplete">
            <div class="row">
                <div class="col-md-2">
                    <fa-icon [icon]="['far', 'square-check']" size="5x" style="color: rgb(92, 184, 92); margin-top: 15px;"></fa-icon>
                </div>
                <div class="col-md-10">
                    <h4 fp-translate>MyApp.UserActivation_Success_Title</h4>
                    <p [innerHtml]="activationSuccessText">

                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>
    <div class="row" *ngIf="isDebug">
        <div class="col-md-12">
            &nbsp;
        </div>
    </div>
    <fpDebugDisplay [isDebug]="isDebug">
        passwordMisMatch: {{passwordMisMatch}}
        <br />
        data.activationKey: {{data.activationKey}}
        <br />
        data.activationKeyValid: {{data.activationKeyValid}}
        <br />
        data.password: {{data.password}}
        <br />
        data.confirmPassword: {{data.confirmPassword}}
        <br />
        data.activationComplete: {{data.activationComplete}}
        <br />
        activationSuccessText: {{activationSuccessText}}
    </fpDebugDisplay>
</form>
