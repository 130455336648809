import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { AppModule } from './app.module';
import 'zone.js';

import './../../Content/scss/vendors.scss';
import './../../Content/scss/styles.scss';

enableProdMode();
platformBrowserDynamic().bootstrapModule(AppModule, { preserveWhitespaces: true })
    .catch(err => console.error(err));