import { Component } from "@angular/core";
import { BaseRouteComponent } from "./base/baseRoute.component";
import { baseRouteDeps, BaseRouteDepenciesFactory, BaseRouteDependencies } from "./base/baseRouteDependencies.provider";
import { ActivateMenuOptions } from "../../../ScriptsModels/MenuItem";
import { ConfigurationService } from "../services/configuration.service";

interface IDashboardDetailData {
}

@Component({
    templateUrl: "./dashboard.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})
export class DashboardComponent extends BaseRouteComponent<IDashboardDetailData> {
    constructor(baseRouteDeps: BaseRouteDependencies,
        private configurationService: ConfigurationService) {
        super("DashboardComponent", baseRouteDeps);

        this.activateMenuOptions = new ActivateMenuOptions("nmuDashboard");
    }

    protected configure() {
        this.dataLoaded = () => {
        }

        this.data = {};
        this.baseRouteDeps.mainContextService.title = "";
    }
}