<form #myForm="ngForm" novalidate>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <h1 fp-translate>MyApp.UserRegistration_Title</h1>
                </div>
            </div>
            <ng-container *ngIf="!mailSent">
                <div class="form-horizontal">
                    <!--Backend error messages-->
                    <fp-error-display [errors]="errors"
                                      [warnings]="warnings"></fp-error-display>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <span fp-translate>MyApp.UserRegistration_Info</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        &nbsp;
                    </div>
                </div>
                <div class="row" *ngIf="!orgIdentificationNumberIsInUse">
                    <div class="col-md-5">
                        <fp-label label="Users.User_Email"></fp-label>
                    </div>
                    <div class="col-md-7">
                        <fp-textbox name="Users.User_Email"
                                    [(ngModel)]="data.email"
                                    maxlength="50"
                                    required="true"></fp-textbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-5">
                        <fp-label label="Users.UserPartyRegistration_OrganisationIdentificationNumber"></fp-label>
                    </div>
                    <div class="col-md-7">
                        <fp-textbox name="Users.UserPartyRegistration_OrganisationIdentificationNumber"
                                    maxlength="20"
                                    [(ngModel)]="data.organisationIdentificationNumber"
                                    helpTlk="MyApp.VatNumber_HelpMessage"
                                    required="true"
                                    [readMode]="crmTrackingCode"></fp-textbox>
                    </div>
                </div>

                <ng-container *ngIf="!orgIdentificationNumberIsInUse">
                    <div class="row">
                        <div class="col-md-12">
                            <h4 fp-translate>MyApp.PersonalInformation</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <fp-label label="MyApp.Salutation"></fp-label>
                        </div>
                        <div class="col-md-4">
                            <fp-buttongroup name="MyApp.Salutation"
                                            [data]="genderOptions"
                                            [(ngModel)]="data.luGenderId"
                                            [stretched]="true"
                                            required="true">
                            </fp-buttongroup>
                            <!--<div class="fost-radio" *ngFor="let g of luGenders">
                <input type="radio" id="radio_{{g.luGenderCode}}" name="radio_{{g.luGenderCode}}"
                       [(ngModel)]="data.luGenderId" [value]="g.id" required />
                <label for="radio_{{g.luGenderCode}}" class="control-normal-label">
                    {{g.salutationShortName}}
                </label>
            </div>-->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <fp-label label="Users.User_FirstName"></fp-label>
                        </div>
                        <div class="col-md-7">
                            <fp-textbox name="Users.User_FirstName"
                                        maxlength="256"
                                        [(ngModel)]="data.firstName"
                                        required="true"></fp-textbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <fp-label label="Users.User_LastName"></fp-label>
                        </div>
                        <div class="col-md-7">
                            <fp-textbox name="Users.User_LastName"
                                        maxlength="256"
                                        [(ngModel)]="data.lastName"
                                        required="true"></fp-textbox>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="btn-toolbar">
                                <button class="btn btn-primary" (click)="registerUser()">
                                    <span fp-translate>MyApp.Register</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="mailSent">
                <div class="row success-text">
                    <div class="col-md-2">
                        <fa-icon [icon]="['far', 'envelope']" size="5x"></fa-icon>
                    </div>
                    <div class="col-md-10">
                        <p fp-translate>MyApp.UserRegistration_MailSent_Text</p>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="col-md-3"></div>
    </div>

    <re-captcha size="invisible" *ngIf="!noCaptcha" #invisibleCaptcha (resolved)="onResolveCaptcha($event)" name="captcha" required></re-captcha>

    <!--debug info-->
    <fpDebugDisplay [isDebug]="isDebug">
        <div class="row">
            <div class="col-md-12">
                <pre>{{data | json}}</pre>
            </div>
        </div>
    </fpDebugDisplay>
</form>
