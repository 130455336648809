import * as moment from "moment";

export class IValidityPeriod {
    notValidBeforeDate: Date;
    notValidOnOrAfterDate: Date;
}

export class DateUtils {

    public static NotValidOnOrAfterToValidUntil(notValidOnOrAfter: Date): Date {
        if (notValidOnOrAfter) {
            var m = moment(notValidOnOrAfter);

            return m.add(-1, "day").toDate();
        }

        return null;
    }

    public static ValidUntilToNotValidOnOrAfter(validUntil: Date): Date {
        if (validUntil) {
            var m = moment(validUntil);

            return m.add(1, "day").toDate();
        }

        return null;
    }

    public static NotValidOnOrAfterToValidUntilString(notValidOnOrAfter: Date): string {
        if (notValidOnOrAfter) {
            var m = moment(notValidOnOrAfter);

            return this.DateToString(m.add(-1, "day").toDate());
        }

        return null;
    }

    public static ValidUntilStringToNotValidOnOrAfter(validUntil: string): Date {
        validUntil = validUntil.trim();

        if (validUntil && validUntil.length === 10) {
            var m = moment(validUntil, "DD.MM.YYYY");
            return m.add(1, "day").toDate();
        }

        return null;
    }

    public static DateToString(date: Date): string {
        if (date) {
            var m = moment(date);
            return m.format("DD.MM.YYYY");
        } else {
            return '';
        }
    }

    public static DateToTimeString(date: Date): string {
        if (date) {
            var m = moment(date);
            return m.format("HH:mm");
        } else {
            return '';
        }
    }

    public static DateToYearMonthDay(date: Date): string {
        if (date) {
            var m = moment(date);
            return m.format("YYYY.MM.DD");
        } else {
            return '';
        }
    }

    public static StringToDate(date: string): Date {
        date = date.trim();

        if (date && date.length === 10) {
            var m = moment(date, "DD.MM.YYYY");
            return m.toDate();
        } else {
            return null;
        }
    }

    public static YearMontDayToDate(date: string): Date {
        date = date.trim();

        if (date && date.length === 10) {
            var m = moment(date, "YYYY.MM.DD");
            return m.toDate();
        } else {
            return null;
        }
    }

    /**
    * Converts a 'dd.MM.yyyy' formatted string to iso format ('YYYY-MM-DDTHH:mm:ss')
    */
    public static localizedToIso(localizedString: string, adjustForNotValidOnOrAfter?: boolean) {
        adjustForNotValidOnOrAfter = adjustForNotValidOnOrAfter || false;

        //var d = kendo.parseDate(<any>localizedString, 'dd.MM.yyyy');

        var m = moment(<any>localizedString, "DD.MM.YYYY");
        if (adjustForNotValidOnOrAfter && m) {
            m = m.add(+1, 'day');
        }

        return <any>m.format('YYYY-MM-DDTHH:mm:ss');
    }

    public static validityPeriodsOverlap(period1: IValidityPeriod, period2: IValidityPeriod): boolean {
        return period2.notValidBeforeDate < period1.notValidOnOrAfterDate
            && period2.notValidOnOrAfterDate > period1.notValidBeforeDate;
    }

    public static isDateInPeriod(date: Date, period: IValidityPeriod): boolean {
        return date >= period.notValidBeforeDate
            && date < period.notValidOnOrAfterDate;
    }
}