import { Component, ViewChild } from "@angular/core";
import { Params } from "@angular/router";
import { BaseRouteDependencies, BaseRouteDepenciesFactory, baseRouteDeps } from "../base/baseRouteDependencies.provider";
import { BaseRouteComponent } from "../base/baseRoute.component";
import { UserRepository } from "../../repositories/user.repository";
import { DataTask } from "../../classes/dataTask"
import { RecaptchaComponent } from "ng-recaptcha";

import CoreDomain = FostPlus.Olympus.CoreDomain.Clients.Api;
import UsersDomain = FostPlus.Olympus.UsersDomain.Clients.Api;
import { IButtonGroupItemData } from "../../modules/shared/components/forms/fpButtonGroup.component";
import { ICultureChangedEvent } from "../../services/language.service";

@Component({
    templateUrl: "./userRegistration.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})
export class UserRegistrationComponent extends BaseRouteComponent<UsersDomain.IUserForRegistrationDto> {

    @ViewChild("invisibleCaptcha", { static: false })
    public invisibleCaptcha?: RecaptchaComponent;

    private luLanguages: Array<CoreDomain.ILuLanguageTrInfoDto> = [];

    public luGenders: Array<CoreDomain.ILuGenderTrInfoDto> = [];
    public genderOptions: IButtonGroupItemData[] = [];
    public mailSent = false;
    public orgIdentificationNumberIsInUse = false;

    private crmTrackingCode = '';

    constructor(baseRouteDeps: BaseRouteDependencies,
        private userRepository: UserRepository) {
        super("UserRegistrationComponent", baseRouteDeps);
    }

    protected configure() {
        // Normally called when activating a menu item
        // In this case there is no menu item
        this.baseRouteDeps.mainContextService.refreshBodyOffset();
        this.data = this.getEmptyDetailData();
        this.mailSent = false;

        this.dataLoadFailed = () => {
        };

        this.dataLoaded = () => {
            this.mapLanguageCodeToLuLanguageId();

            this.genderOptions = [];
            this.luGenders.forEach(e => {
                const item: IButtonGroupItemData = {
                    name: e.salutationShortName,
                    value: e.id
                };
                this.genderOptions.push(item);
            });
        };

        this.addDataTask(new DataTask(this.loadLuGenderData, this.processLuGenderData, { reloadOnCultureChange: true, reload: false }));
        this.addDataTask(new DataTask(this.loadLuLanguageData, this.processLuLanguageData, { reloadOnCultureChange: true, reload: false }));

        this.processQueryParams();
    }

    protected queryParamsUpdated(params: Params): void {
        this.clearFeedback();
        this.processQueryParams();
    }

    protected cultureChanged(event: ICultureChangedEvent) {
        this.CheckOrgIdentificationNumberIsInUse();
    }

    /* Actions */

    public registerUser(): void {
        this.clearFeedback();
        if (!this.noCaptcha) {
            this.invisibleCaptcha.execute();
        }
        else {
            this.sendRegisterUserRequest(null);
        }
    }

    public onResolveCaptcha(reCaptchaToken: string) {
        if (reCaptchaToken == null) {
            return;
        }

        this.sendRegisterUserRequest(reCaptchaToken);
        this.invisibleCaptcha.reset();
    }

    /* Helper funcs */

    private sendRegisterUserRequest(reCaptchaToken: string) {
        if ((this.isFormValid() && !this.isBlocking())) {
            this.startBlocking();

            const request: UsersDomain.IUserForRegistrationRequest = {
                user: this.data,
                reCaptchaToken: reCaptchaToken,
                crmTrackingCode: this.crmTrackingCode
            };

            this.userRepository.registerUser(request)
                .then((result) => {
                    this.mailSent = true;

                    this.setPristine();

                    this.stopBlocking();
                    this.scrollToTop();
                })
                .catch((error) => {
                    this.handleFeedback(error);

                    this.setPristine();

                    this.stopBlocking();
                    this.scrollToTop();
                });
        }
    }

    private processQueryParams(): void {
        this.crmTrackingCode = '';

        const lang = this.queryParams["locale"] as string;
        const langChanged = lang && lang.toLowerCase() !== this.baseRouteDeps.languageService.getLanguage();

        if (langChanged) {
            this.baseRouteDeps.languageService.updateLanguage(lang);
        }        

        // build the queryParams-string without 'locale' as CRMTrackingCode
        const paramKeys = Object.keys(this.queryParams);
        paramKeys.forEach(key => {
            if (key != "locale" && key != "noCaptcha") {
                this.crmTrackingCode += `&${key}=${this.queryParams[key]}`;
            }
        });

        // When there is a crmTrackingCode, we get info from CRM
        //  => OrganisationIdentifactionNumber via (required) accountId query param
        //      => when number already in use, we block UI
        //  => FirstName, LastName and Email via (optional) contactId query param 
        if (this.crmTrackingCode) {
            this.startBlocking();

            this.userRepository.getUserForRegistration(this.crmTrackingCode).then((result) => {
                if (result) {
                    this.data = result.user;
                    this.mapLanguageCodeToLuLanguageId();

                    this.orgIdentificationNumberIsInUse = result.organisationIdentificationNumberIsInUse;
                    this.CheckOrgIdentificationNumberIsInUse();
                }
            }).catch((error) => {
                this.handleFeedback(error);
            }).finally(() => {
                this.stopBlocking();
            });
        }
    }

    private CheckOrgIdentificationNumberIsInUse(): void {
        this.clearFeedback();

        if (this.orgIdentificationNumberIsInUse) {
            this.baseRouteDeps.translateService.get('Users.UserPartyRegistration_Validation_OrganisationIdentificationNumberNotUnique').subscribe((result) => {
                this.errors.push({
                    feedbackMessageType: 1,
                    message: result
                })
            });
        }
    }

    private mapLanguageCodeToLuLanguageId(): void {
        // At this point (dataloaded), the language is already changed by processQueryParams (if needed)
        //  => also when language is changed by user in UI, the dataloaded gets called, so this mapping occurs
        const code = this.baseRouteDeps.languageService.getLanguage();
        const language = this.luLanguages.find(e => e.parentLuLanguageCode.toLowerCase() === code.toLowerCase());

        this.data.luLanguageId = null;

        if (language) {
            this.data.luLanguageId = language.id;
        }
    }

    private getEmptyDetailData(): UsersDomain.IUserForRegistrationDto {
        return {
            email: '',
            firstName: '',
            lastName: '',
            organisationIdentificationNumber: '',
            luGenderId: null,
            luLanguageId: null
        }
    }

    /* DataTask funcs */

    private loadLuGenderData(resolve: (data: Array<CoreDomain.ILuGenderTrInfoDto>) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.lookupDataRepository.getLuGenderTrsInfo().then((result) => {
            resolve(result.result);
        }).catch((error) => {
            reject(error);
        });
    }

    private processLuGenderData(data: Array<CoreDomain.ILuGenderTrInfoDto>) {
        this.luGenders = data;
    }

    private loadLuLanguageData(resolve: (data: Array<CoreDomain.ILuLanguageTrInfoDto>) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.lookupDataRepository.getLuLanguageTrsInfo().then((result) => {
            resolve(result.result);
        }).catch((error) => {
            reject(error);
        });
    }

    private processLuLanguageData(data: Array<CoreDomain.ILuLanguageTrInfoDto>) {
        this.luLanguages = data;
    }
}

