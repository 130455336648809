import { Component, ViewChild } from "@angular/core";
import { BaseRouteDependencies, BaseRouteDepenciesFactory, baseRouteDeps } from "./base/baseRouteDependencies.provider";
import { BaseRouteComponent } from "./base/baseRoute.component";
import { UserRepository } from "../repositories/user.repository";
import { RecaptchaComponent } from "ng-recaptcha";
import { Params } from "@angular/router";

interface IForgotUsernameData {
    email: string;
    mailSent: boolean;
}

@Component({
    templateUrl: "./forgotUsername.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})
export class ForgotUsernameComponent extends BaseRouteComponent<IForgotUsernameData> {

    @ViewChild("invisibleCaptcha", { static: false })
    public invisibleCaptcha?: RecaptchaComponent;

    constructor(baseRouteDeps: BaseRouteDependencies,
        private userRepository: UserRepository) {
        super("ForgotUsernameComponent", baseRouteDeps);
    }

    protected configure() {
        // Normally called when activating a menu item
        // In this case there is no menu item
        this.baseRouteDeps.mainContextService.refreshBodyOffset();
        this.data = this.getEmptyDetailData();

        this.processQueryParams();
    }

    protected queryParamsUpdated(params: Params): void {
        this.clearFeedback();
        this.processQueryParams();
    }

    private processQueryParams(): void {
        var lang = <string>this.queryParams["locale"];
        var langChanged = lang && lang.toLowerCase() !== this.baseRouteDeps.languageService.getLanguage();

        if (langChanged) {
            this.baseRouteDeps.languageService.updateLanguage(lang);
        }
    }

    private getEmptyDetailData(): IForgotUsernameData {
        return {
            email: '',
            mailSent: false
        };
    }

    public submitForm() {
        this.clearFeedback();
        if (!this.noCaptcha) {
            this.invisibleCaptcha.execute();
        }
        else {
            this.sendUserForgotUsernameEmailCreateRequest(null);
        }
    }

    public onResolveCaptcha(reCaptchaToken: string) {
        if (reCaptchaToken == null) {
            return;
        }

        this.sendUserForgotUsernameEmailCreateRequest(reCaptchaToken);
        this.invisibleCaptcha.reset();
    }

    private sendUserForgotUsernameEmailCreateRequest(reCaptchaToken: string) {
        if (this.isFormValid() && !this.isBlocking()) {

            this.startBlocking();

            const languageCode = this.baseRouteDeps.languageService.getLanguage();
            this.userRepository.userForgotUsernameEmailCreate(this.data.email, languageCode, reCaptchaToken)
                .then((result) => {
                    this.data.mailSent = true;

                    this.setPristine();

                    this.stopBlocking();
                    this.scrollToTop();
                    this.invisibleCaptcha.reset();
                })
                .catch((error) => {
                    this.handleFeedback(error);

                    this.setPristine();

                    this.stopBlocking();
                    this.scrollToTop();
                    this.invisibleCaptcha.reset();
                });
        }
    }
}