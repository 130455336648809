import * as $ from "jquery";

// angular imports
import { NgModule, APP_INITIALIZER, ErrorHandler, Injectable } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule, Title } from "@angular/platform-browser";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Route } from "@angular/router";
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


// kendo ui for angular
import { GridModule } from "@progress/kendo-angular-grid";
import { IntlModule, load, IntlService } from '@progress/kendo-angular-intl';
import { DropDownsModule, MultiSelectModule } from "@progress/kendo-angular-dropdowns";
import { TextBoxModule, TextAreaModule, NumericTextBoxModule, MaskedTextBoxModule } from "@progress/kendo-angular-inputs";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs"
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogModule } from "@progress/kendo-angular-dialog";
import { TooltipModule } from "@progress/kendo-angular-tooltip";

// FontAwesome
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';

// Interceptors
import { InjectHeadersInterceptor } from "./interceptors/injectHeaders.interceptor";
import { RequestToJsonInterceptor } from "./interceptors/requestToJson.interceptor";
import { ResponseToPoJoInterceptor } from "./interceptors/responseToPoJo.interceptor";

// Components
import { AppComponent } from "./app.component";
import { NavComponent } from "./components/nav.component";
import { ContextComponent } from "./components/context.component";
import { MenuComponent } from "./components/menu.component";

// Shared Module
import { SharedModule } from "./modules/shared/shared.module"

// Router components
import { DashboardComponent } from "./routeComponents/dashboard.component";
import { ActivateUserComponent } from "./routeComponents/activateUser.component";
import { ForgotPasswordComponent } from "./routeComponents/forgotPassword.component";
import { ResetPasswordComponent } from "./routeComponents/resetPassword.component";
import { UserRegistrationComponent } from "./routeComponents/userRegistration/userRegistration.component";
import { FostpackForgotPasswordComponent } from "./routeComponents/fostpackForgotPassword.component";
import { FostpackResetPasswordComponent } from "./routeComponents/fostpackResetPassword.component";
import { FostpackForgotUsernameComponent } from "./routeComponents/fostpackForgotUsername.component";
import { ForgotUsernameComponent } from "./routeComponents/forgotUsername.component";
import { UserIAMRegistrationAnonymousComponent } from "./routeComponents/userIAMRegistrationAnonymous/userIAMRegistrationAnonymous.component";

//FostpackForgotUsernameComponent

// Services & Providers
import { AnalyticsService } from "./services/analytics.service";
import { ConfigurationService } from "./services/configuration.service";
import { LanguageService } from "./services/language.service";
import { HttpProvider } from "./services/http.provider";
import { MenuService } from "./services/menu.service";
import { FilterCacheService } from "./services/filterCache.service";
import { LogService } from "./services/log.service";
import { MainContextService } from "./services/mainContext.service";
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaFormsModule } from 'ng-recaptcha';
import { LeavePageService } from './services/leavePage.service';

// do not provide this anymore ... use fpCacheServiceFactory
//import { CacheService, LocalStorageServiceModule } from "ng2-cache-service";
import { ErrorHandlingService } from "./services/errorHandling.service";
import { LookupDataUtilitiesService } from "./services/lookupDataUtilities.service";
import { CanDeactivateGuardService } from "./services/canDeactivateGuard.service";
import { FpIntlService } from "./services/fpIntlService";
import { FpCacheServiceFactory } from "./services/fpCacheService.service";
import { GlobalErrorHandler } from './services/globalErrorHandler.service';

// Utilities
import { AppSettingsLoader } from "./utilities/appSettingsLoader"
import { WebApiTranslateLoader } from "./utilities/translate/webApiTranslateLoader";
import { FpMissingTranslationHandler } from "./utilities/translate/missingTranslationHandler";
import { UserErrorHandler } from "./classes/errorHandling"

// Directives

// Repositories
import { LookupDataRepository } from "./repositories/lookupData.repository";
import { UserRepository } from "./repositories/user.repository";
// File upload
import { FileUploadModule } from "ng2-file-upload";

var suplLikelySubtags = require("cldr-data/supplemental/likelySubtags.json");
var suplCurrencyData = require("cldr-data/supplemental/currencyData.json");
var suplweekData = require("cldr-data/supplemental/weekData.json");
var numberNl = require("cldr-data/main/nl-BE/numbers.json");
var currenciesNl = require("cldr-data/main/nl-BE/currencies.json");
var dateFieldsNl = require("cldr-data/main/nl-BE/dateFields.json");
var caGregorianNl = require("cldr-data/main/nl-BE/ca-gregorian.json");
var timeZoneNl = require("cldr-data/main/nl-BE/timeZoneNames.json");
var numberFr = require("cldr-data/main/fr-BE/numbers.json");
var currenciesFr = require("cldr-data/main/fr-BE/currencies.json");
var dateFieldsFr = require("cldr-data/main/fr-BE/dateFields.json");
var caGregorianFr = require("cldr-data/main/fr-BE/ca-gregorian.json");
var timeZoneFr = require("cldr-data/main/fr-BE/timeZoneNames.json");

declare var myApp: any;

var rootUrl: string = $('#linkRoot').attr('href');
var appName: string = 'MyFost';

var receptchaSettingsUtilFactory = (config: ConfigurationService) => {
    return {
        siteKey: config.getReCaptchaPublicKey()
    } as RecaptchaSettings
}

var recaptchaKeyFactory = (config: ConfigurationService) => {
    return config.getReCaptchaPublicKey();
}

// commonRoutePrefix is needed when putting organisationGroup and selectedPartyId in url's
//var commonRoutePrefix = ":organisationGroupId/:selectedPartyId/";
var commonRoutePrefix = "";
var routes: Route[] = [
    //{ path: '', component: DashboardComponent },
    { path: '', component: DashboardComponent, pathMatch: 'full' },
    { path: commonRoutePrefix + 'dashboard', component: DashboardComponent },
    //{ path: commonRoutePrefix + 'activate', component: ActivateUserComponent, canDeactivate: [CanDeactivateGuardService] }
    { path: commonRoutePrefix + 'activate', component: ActivateUserComponent },
    { path: commonRoutePrefix + 'password/forgot', component: ForgotPasswordComponent },
    { path: commonRoutePrefix + 'password/reset', component: ResetPasswordComponent },
    { path: commonRoutePrefix + 'userRegistration', component: UserRegistrationComponent },
    { path: commonRoutePrefix + 'fostpack1/password/forgot', component: FostpackForgotPasswordComponent },
    { path: commonRoutePrefix + 'fostpack1/password/reset', component: FostpackResetPasswordComponent },
    { path: commonRoutePrefix + 'fostpack1/username/forgot', component: FostpackForgotUsernameComponent },
    { path: commonRoutePrefix + 'username/forgot', component: ForgotUsernameComponent },
    { path: commonRoutePrefix + 'userIAMRegistration', component: UserIAMRegistrationAnonymousComponent }
];

// Force locale fr-BE to use '.' as thousand separator
numberFr.main["fr-BE"].numbers["symbols-numberSystem-latn"].group = ".";

load(
    suplLikelySubtags,
    suplCurrencyData,
    suplweekData,

    numberNl,
    currenciesNl,
    dateFieldsNl,
    caGregorianNl,
    timeZoneNl,
    
    numberFr,
    currenciesFr,
    dateFieldsFr,
    caGregorianFr,
    timeZoneFr,
);

import {
    faCompress,
    faExpand,
    faInfoCircle,
    faUser,
    faDownload,
    faChevronRight,
    faExclamationCircle,
    faChevronDown,
    faArrowsRotate
} from '@fortawesome/free-solid-svg-icons';

import {
    faSquare as farSquare,
    faSquareCheck as farSquareCheck,
    faEnvelope as farEnvelope
} from '@fortawesome/free-regular-svg-icons';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forRoot(routes, { useHash: true }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpProvider) => new WebApiTranslateLoader(http,
                    rootUrl + 'api/translations/{lang}/{part}'),
                deps: [HttpProvider, 'rootUrl']
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: FpMissingTranslationHandler }
        }),
        GridModule,
        ToastrModule.forRoot({
            autoDismiss: true,
            timeOut: 3000,
            newestOnTop: false,
            closeButton: false,
            maxOpened: 5,
            positionClass: "toast-top-right",
            //animate: "fade",
            enableHtml: false
        }),
        DropDownsModule,
        TextBoxModule,
        TextAreaModule,
        NumericTextBoxModule,
        MaskedTextBoxModule,
        MultiSelectModule,
        NoopAnimationsModule,
        DateInputsModule,
        ButtonsModule,
        DialogModule,
        TooltipModule,
        FileUploadModule,
        IntlModule,
        SharedModule,
        HttpClientModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        RecaptchaV3Module,
        FontAwesomeModule
    ],
    declarations: [
        AppComponent,
        NavComponent,
        MenuComponent,
        ContextComponent,
        DashboardComponent,
        ActivateUserComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        UserRegistrationComponent,
        FostpackForgotPasswordComponent,
        FostpackResetPasswordComponent,
        FostpackForgotUsernameComponent,
        ForgotUsernameComponent,
        UserIAMRegistrationAnonymousComponent
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        { provide: 'rootUrl', useValue: rootUrl },
        { provide: 'appName', useValue: appName },
        ConfigurationService,
        LogService,
        AppSettingsLoader,
        { provide: APP_INITIALIZER, useFactory: (appSettingsLoader: AppSettingsLoader) => () => appSettingsLoader.loadAppSettings(), deps: [AppSettingsLoader, HttpProvider, ConfigurationService, 'rootUrl'], multi: true },
        { provide: RECAPTCHA_SETTINGS, useFactory: receptchaSettingsUtilFactory, deps: [ConfigurationService]},
        LanguageService,
        AnalyticsService,
        { provide: ErrorHandler, useClass: UserErrorHandler },
        FpCacheServiceFactory,
        Title,
        { provide: HTTP_INTERCEPTORS, useClass: InjectHeadersInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RequestToJsonInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ResponseToPoJoInterceptor, multi: true },
        HttpProvider,
        MenuService,
        FilterCacheService,
        MainContextService,
        ErrorHandlingService,
        LookupDataRepository,
        LookupDataUtilitiesService,
        LeavePageService,
        CanDeactivateGuardService,
        { provide: IntlService, useClass: FpIntlService },
        UserRepository,
        { provide: RECAPTCHA_V3_SITE_KEY, useFactory: recaptchaKeyFactory, deps: [ConfigurationService]}
        
    ],
    exports: [
        FontAwesomeModule
    ]
})

export class AppModule {
    constructor(
        library: FaIconLibrary) {

        library.addIcons(
            // FA solid icons
            faUser, faExclamationCircle, faDownload, faExpand, faCompress, faInfoCircle, faChevronRight,
            faChevronDown, faArrowsRotate,

            // FA regular icons
            farSquare, farSquareCheck, farEnvelope
        );
    }
}