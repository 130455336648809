import * as jquery from "jquery";

export class Utils {

    public static deepCopy(a: any): any {
        return jquery.extend(true, {}, a);
    }

    public static arrayEquals(arr1: Array<any>, arr2: Array<any>) {
        return arr1 && arr2
            && arr1.every(item => arr2.includes(item))
            && arr1.length == arr2.length;
    }

    public static hasLink(dataItem: any, rel: string): boolean {
        var hasLink: boolean = false;

        if (dataItem.links) {
            var index = (<Array<FostPlus.Olympus.UsersDomain.Clients.Api.ILinkDto>>dataItem.links).findIndex((link) => {
                return link.rel.toLowerCase() == rel.toLowerCase();
            });

            hasLink = (index >= 0);
        }

        return hasLink;
    }

    public static isObject(x) {
        return x != null && typeof x === 'object';
    }

    public static isArray(x) {
        if (Array.isArray) {
            return Array.isArray(x);
        } else {
            return x && typeof x.length === 'number';
        };
    }

    public static isNumeric(val) {
        return !Utils.isArray(val) && (val - parseFloat(val) + 1) >= 0;
    }
}