import { Location } from "@angular/common"
import { Router, ActivatedRoute } from "@angular/router";
import { ConfigurationService } from "../../services/configuration.service";
import { LanguageService } from "../../services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { LookupDataUtilitiesService } from "../../services/lookupDataUtilities.service";
import { LookupDataRepository } from "../../repositories/lookupData.repository";
import { ErrorHandlingService } from "../../services/errorHandling.service";
import { MainContextService } from "../../services/mainContext.service";
import { MenuService } from "../../services/menu.service";
import { AnalyticsService } from "../../services/analytics.service";
import { LogService } from "../../services/log.service"
import { BlockUiService } from "../../services/blockUi.service";
import { LeavePageService } from "../../services/leavePage.service";

/// Adding or removing dependancies should be done in all 3 exports
///   - class BaseRouteDependencies
///   - function BaseRouteDepenciesFactory
///   - array baseRouteDeps

export class BaseRouteDependencies {
    constructor(public mainContextService: MainContextService,
        public menuService: MenuService,
        public configuration: ConfigurationService,
        public translateService: TranslateService,
        public languageService: LanguageService,
        public toastr: ToastrService,
        public location: Location,
        public lookupDataUtilities: LookupDataUtilitiesService,
        public lookupDataRepository: LookupDataRepository,
        public errorHandling: ErrorHandlingService,
        public route: ActivatedRoute,
        public router: Router,
        public analyticsService: AnalyticsService,
        public logService: LogService,
        public blockUiService: BlockUiService,
        public leavePageService: LeavePageService) {
    } 
}

export function BaseRouteDepenciesFactory(
    mainContext: MainContextService,
    menuService: MenuService,
    configuration: ConfigurationService,
    translateService: TranslateService,
    languageService: LanguageService,
    toastr: ToastrService,
    location: Location,
    lookupDataUtilities: LookupDataUtilitiesService,
    lookupDataRepository: LookupDataRepository,
    errorHandling: ErrorHandlingService,
    route: ActivatedRoute,
    router: Router,
    analyticsService: AnalyticsService,
    logService: LogService,
    blockUiService: BlockUiService,
    leavePageService: LeavePageService): BaseRouteDependencies {
    return new BaseRouteDependencies(mainContext,
        menuService,
        configuration,
        translateService,
        languageService,
        toastr,
        location,
        lookupDataUtilities,
        lookupDataRepository,
        errorHandling,
        route,
        router,
        analyticsService,
        logService,
        blockUiService,
        leavePageService);
}

export var baseRouteDeps: Array<any> = [
    MainContextService,
    MenuService,
    ConfigurationService,
    TranslateService,
    LanguageService,
    ToastrService,
    Location,
    LookupDataUtilitiesService,
    LookupDataRepository,
    ErrorHandlingService,
    ActivatedRoute,
    Router,
    AnalyticsService,
    LogService,
    BlockUiService,
    LeavePageService
];