import { Component } from "@angular/core";
import { Params } from "@angular/router";
import { BaseRouteDependencies, BaseRouteDepenciesFactory, baseRouteDeps } from "./base/baseRouteDependencies.provider";
import { BaseRouteComponent } from "./base/baseRoute.component";
import { UserRepository } from "../repositories/user.repository";
import { DataTask } from "../classes/dataTask"

import UsersDomain = FostPlus.Olympus.UsersDomain.Clients.Api;

interface IForgotPasswordData {
    windowsLogon: string;
    email: string;
    mailSent: boolean;
}

@Component({
    templateUrl: "./forgotPassword.component.html",
    providers: [
        { provide: BaseRouteDependencies, useFactory: BaseRouteDepenciesFactory, deps: baseRouteDeps }
    ]
})
export class ForgotPasswordComponent extends BaseRouteComponent<IForgotPasswordData> {
    constructor(baseRouteDeps: BaseRouteDependencies,
        private userRepository: UserRepository) {
        super("ForgotPasswordComponent", baseRouteDeps);
    }

    public mailSentText: string = "";

    protected configure() {
        // Normally called when activating a menu item
        // In this case there is no menu item
        this.baseRouteDeps.mainContextService.refreshBodyOffset();
        this.data = this.getEmptyDetailData();

        this.dataLoadFailed = () => {
        };

        this.dataLoaded = () => {
        };

        this.addDataTask(new DataTask(this.loadMailSentText, this.processMailSentText, { reloadOnCultureChange: true, reload: false }));

        this.processQueryParams();
    }

    protected queryParamsUpdated(params: Params): void {
        this.clearFeedback();
        this.processQueryParams();
    }

    private processQueryParams(): void {
        var lang = <string>this.queryParams["locale"];
        var langChanged = lang && lang.toLowerCase() !== this.baseRouteDeps.languageService.getLanguage();

        if (langChanged) {
            this.baseRouteDeps.languageService.updateLanguage(lang);
        } 
    }

    private getEmptyDetailData(): IForgotPasswordData {
        return {
            windowsLogon: '',
            email: '',
            mailSent: false
        };
    }

    private loadMailSentText(resolve: (data: string) => void, reject: (reason: any) => void) {
        this.baseRouteDeps.translateService.get("MyApp.UserPasswordReset_MailSent_Text").subscribe((value) => {
            resolve(value);
        });
    }

    private processMailSentText(data: string) {
        this.mailSentText = data.replace("\r\n", "<br>");
    }

    public sendResetEmail() {
        this.clearFeedback();

        if (this.isFormValid() && !this.isBlocking()) {
            this.startBlocking();

            this.userRepository.createUserPasswordResetEmail(this.data.email, this.data.windowsLogon)
                .then((result) => {
                    this.data.mailSent = true;

                    this.setPristine();

                    this.stopBlocking();
                    this.scrollToTop();
                })
                .catch((error) => {
                    this.handleFeedback(error);

                    this.setPristine();

                    this.stopBlocking();
                    this.scrollToTop();
                });
        }
    }
}


