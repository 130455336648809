import { Injectable, Inject } from '@angular/core';
import { HttpProvider } from "../services/http.provider";
import { WrappedResult } from "../classes/errorHandling";
import { IPromiseErrorResponse } from "../interfaces/errors";

import { LanguageService } from "../services/language.service";
import { LogService } from "../services/log.service";
import { FpCacheServiceFactory, FpCacheService, FpCacheStoragesEnum } from "../services/fpCacheService.service";
import { ConfigurationService } from "../services/configuration.service";
import { ToastrService } from "ngx-toastr";
import { ErrorHandlingService } from "../services/errorHandling.service";
import { LookupDataUtilitiesService } from "../services/lookupDataUtilities.service";
import { Utils } from "../utilities/utils";

import FpCoreDomain = FostPlus.Olympus.CoreDomain.Clients.Api;

export interface ILookupDataRepository {
    //getCountries(): Promise<WrappedResult<FpCoreDomain.ICountryTrInfoDto>>;
    getLuGenderTrsInfo(): Promise<WrappedResult<FpCoreDomain.ILuGenderTrInfoDto>>;
    getLuLanguageTrsInfo(): Promise<WrappedResult<FpCoreDomain.ILuLanguageTrInfoDto>>;
    getLuSalutationTrsInfo(): Promise<WrappedResult<FpCoreDomain.ILuSalutationTrInfo>>;
}

@Injectable()
export class LookupDataRepository implements ILookupDataRepository {
    //static $inject = ['$resource', '$q', '$log', 'languageService', 'dataCache', 'rootUrl', 'errorHandling', 'lookupDataUtilities'];

    private logName = 'lookupDataRepository';
    //private resource: ng.resource.IResourceClass<any>;

    constructor(private config: ConfigurationService, 
        private languageService: LanguageService,
        private logService: LogService,
        private fpCacheServiceFactory: FpCacheServiceFactory,
        private http: HttpProvider,
        @Inject('rootUrl') private rootUrl: string,
        private toastr: ToastrService,
        private errorHandling: ErrorHandlingService,
        private lookupDataUtilities: LookupDataUtilitiesService
    ) {
        this.localStorageCache = fpCacheServiceFactory.getOrCreate(FpCacheStoragesEnum.MEMORY, "lu");
    }

    private localStorageCache: FpCacheService;

    /**
     * generic function to load lookupData form the server
     * @route The url part to the webApi
     * parseFn An optional function to parse the result
     */
    private getFromCacheOrLoad = (route: string, parseFn: any): any => {
        var userLanguage: string = this.languageService.getLanguage();
        var key: string = route + '' + userLanguage;
        var promise = new Promise((resolve, reject) => {

            // this.$log.info(this.logName + ' - getting ' + key);
            var data: any = this.localStorageCache.get(key);

            if (data) {

                // make a copy of the object ( to avoid that clients modify what is in the cache)
                var d = Utils.deepCopy(data);

                resolve(d);
            } else {
                this.http.get(this.rootUrl + "api/" + route).then((okResponse: any) => {
                    this.logService.log(this.logName + ' - got ' + key + ' from server');
                    var d = okResponse;
                    if (parseFn) {
                        d = parseFn(okResponse);
                    }

                    // make a copy of the object ( to avoid that clients modify what is in the cache)
                    d = Utils.deepCopy(d);

                    this.localStorageCache.set(key, d, this.config.getCacheValidity());
                    resolve(d);
                }).catch((errorResponse: IPromiseErrorResponse) => {
                    var msg = this.logName + ' - error ' + key;
                    this.logService.error(msg);
                    var wrappedResult = this.errorHandling.createFailureWrappedResult(errorResponse);

                    try {
                        if (this.config.isDebug()) {
                            this.toastr.error(wrappedResult.getDisplayError(), msg);
                        }
                    } catch (e) { }

                    reject(wrappedResult);
                });
            }
        });

        return promise;
    };

    private addBlankItem = (data: Array<any>, idName?: string) => {
        if (!idName) {
            idName = 'id';
        }
        var emptyitem = JSON.parse('{ "' + idName + '": "", "displayName": " ", "isActive": true }');
        emptyitem.id = '';
        data.splice(0, 0, emptyitem);
    };

    //getCountries(): Promise<WrappedResult<FostPlus.CoreDomain.Services.Contract.Dtos.ICountryTrInfoDto>> {
    //    var result = this.getFromCacheOrLoad('countries',
    //        (data) => {
    //            // displayName => Belgie (BE)
    //            data.countries.forEach(function (value, key) {
    //                value.displayName = value.name + ' (' + value.countryCode + ')';
    //            });

    //            return this.errorHandling.createOkWrappedResult(data.countries);
    //        });

    //    return result;
    //};

    getLuGenderTrsInfo = () => {
        var result = this.getFromCacheOrLoad('luGenders',
            (data) => {
                data.genders.forEach(function (value, key) {
                    value.displayName = value.name;
                });

                return this.errorHandling.createOkWrappedResult(data.genders);
            });

        return result;
    };

    getLuLanguageTrsInfo(): Promise<WrappedResult<FpCoreDomain.ILuLanguageTrInfoDto>> {
        var result = this.getFromCacheOrLoad('luLanguages',
            (data) => {
                data.luLanguages.forEach(function (value, key) {
                    value.displayName = value.name;
                });

                return this.errorHandling.createOkWrappedResult(data.luLanguages);
            });

        return result;
    };

    getLuSalutationTrsInfo(): Promise<WrappedResult<FpCoreDomain.ILuSalutationTrInfo>> {
        var result = this.getFromCacheOrLoad('luSalutations',
            (data) => {
                data.luSalutations.forEach(function (value, key) {
                    value.displayName = value.name;
                });

                return this.errorHandling.createOkWrappedResult(data.luSalutations);
            });

        return result;
    };
};