import { OnInit, OnDestroy, ViewChild, HostListener, Type, Directive } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Params } from "@angular/router";
import { BaseRouteDependencies } from "./baseRouteDependencies.provider";
import { FeedbackMessage, FeedbackMessageType } from "../../classes/errorHandling";
import { ActivateMenuOptions } from "../../../../ScriptsModels/MenuItem";
import { Subscription, Observable } from 'rxjs';
import { ICultureChangedEvent } from "../../services/language.service";
import { NotImplementedError } from "../../classes/errors";
import { IDataTask, DataTask } from "../../classes/dataTask";

export interface IController<TData> {
    data: TData;
    isDebug: boolean;
    isAuthorized: boolean;
    errors: Array<FeedbackMessage>;
    warnings: Array<FeedbackMessage>;
    leavePage: string;
    activateMenuOptions: ActivateMenuOptions;
    loggingInfo: string;
}

@Directive()
export class BaseRouteComponent<TData> implements IController<TData>, OnInit, OnDestroy {
    @ViewChild("myForm", { static: false }) protected myForm: NgForm;

    // Display warning when closing tab and form isDirty == true
    @HostListener('window:beforeunload', ['$event'])
    private handleBeforeUnload(event) {
        if (this.isDirty()) {
            event.returnValue = this.leavePage;
        }
    }

    constructor(private name: string,
        protected baseRouteDeps: BaseRouteDependencies) {

        this.activateMenuOptions = {
            name: null,
            showSubMenu: false
        };
    }

    private loadCalledAgain: boolean;
    private loadStart: Date;

    private onRefreshRequestedSub: Subscription;
    private onCultureChangedSub: Subscription;
    private onParamChangeSub: Subscription;
    private onQueryParamChangeSub: Subscription;
    private onLeavePageChosenSub: Subscription;

    public data: TData;
    public isDebug = false;
    public isAuthorized = true;
    public errors: Array<FeedbackMessage> = [];
    public warnings: Array<FeedbackMessage> = [];
    public leavePage: string;
    public activateMenuOptions: ActivateMenuOptions;
    public loggingInfo: string;
    public noHistoryFallBackUrl: string = '';
    public isNewTab: boolean = false;
    public noCaptcha: boolean = false;

    public params: Params | undefined;
    public queryParams: Params | undefined;

    private dataTasks: Array<IDataTask<Array<any>>> = [];
    private allDataLoaded: Promise<any>;
    private activeWatches: Array<any> = [];

    private _isLoading: boolean = false;
    private _isLoaded: boolean = false;
    private _leavePageDirtyAsked: boolean = false;
    private _historyBackRequested = false;
    private _initialized: boolean = false;

    ngOnInit() {
        this.loadStart = this.baseRouteDeps.route.snapshot.root["start"];
        this.isDebug = this.baseRouteDeps.configuration.isDebug();

        // handle events to catch route params
        this.onParamChangeSub = this.baseRouteDeps.route.params.subscribe(this.onParamChange);
        this.onQueryParamChangeSub = this.baseRouteDeps.route.queryParams.subscribe(this.onQueryParamChange);

        this.onLeavePageChosenSub = this.baseRouteDeps.leavePageService.leavePageChoiceSubject.subscribe(this.onLeavePageChosen);

        this.baseRouteDeps.translateService.get("MyApp.LeavePage").subscribe((translation) => {
            this.leavePage = translation;
        });

        this.onCultureChangedSub = this.baseRouteDeps.languageService.onCultureChange.subscribe(this.onCultureChanged);

        this.onRefreshRequestedSub = this.baseRouteDeps.mainContextService.onRefreshRequested.subscribe(this.onRefreshRequested);

        this.configure();
        this.initialize();
    }

    protected configure() {
        throw new NotImplementedError(
            "Configure method not implemented. All components extending the BaseRouteComponent should implement this.");
    }

    protected handleMainContextReady() {

    }

    protected handleRefreshRequested() {

    }

    protected updateUserRights() {

    }

    // Could be refactored, because doHistoryBack does only location back, so guard could show dialog
    // (nothing is done with noHistoryFallBackUrl)
    //  => !! but we WON'T !!
    //  => because of a problem in angular, routing history could be broken by deactivation guard
    //     (https://github.com/angular/angular/issues/13586)
    //  => By showing the dialog manually before guard does, we save a possible case of broken routing history
    public historyBack() {
        // check on dirty
        if (this.isDirty()) {
            this.baseRouteDeps.leavePageService.callDialogOpen();
            // We need to know in the subscription (onLeavePageChosen) the dialog is opened from this method
            this._historyBackRequested = true;
        } else {
            this.doHistoryBack();
        }
    }

    private doHistoryBack() {
        this._leavePageDirtyAsked = true;
        // remember where we are
        // var t = window.location.href;
        const t = this.baseRouteDeps.location.path();
        // try to go back
        this.baseRouteDeps.location.back();
        // window.history.back();
        // check if we did go back (because we are at the start of the history), if not, use fallback url
        if (this.noHistoryFallBackUrl) {
            setTimeout(() => {
                // if (window.location.href == t) {
                if (this.baseRouteDeps.location.isCurrentPathEqualTo(t)) {
                    // this.dependencies.$location.url(this.noHistoryFallBackUrl);
                }
            }, 250);
        }
    }

    public scrollToTop() {
        setTimeout(() => { window.scrollTo(0, 0); });
    }

    protected initialize() {
        this.loadAllData(false);
    }

    protected reload(): boolean {
        return this.loadAllData(false);
    }

    public startBlocking() {
        this.baseRouteDeps.blockUiService.startBlocking();
    }

    public stopBlocking() {
        this.baseRouteDeps.blockUiService.stopBlocking();
    }

    public isBlocking() {
        return this.baseRouteDeps.blockUiService.isBlocking();
    }

    public showSaveConfirmation = (message?: string) => {
        if (message) {
            this.baseRouteDeps.toastr.success(message);
        } else {
            this.baseRouteDeps.translateService.get("MyApp.SaveSucceeded").subscribe((translation) => {
                this.baseRouteDeps.toastr.success(translation);
            });
        }
    };

    public showFileDoesNotExist = (message?: string) => {
        if (message) {
            this.baseRouteDeps.toastr.error(message);
        } else {
            this.baseRouteDeps.translateService.get("MyApp.FileDoesNotExist").subscribe((translation) => {
                this.baseRouteDeps.toastr.error(translation);
            });
        }
    };

    public initAuthorization(resoure: string, action: string) {
        // TODO
        //this.isAuthorized = this.dependencies.permissionService.isAuthorized(resoure, action);
    }

    protected addDataTask(dataTask: DataTask<any>) {
        this.dataTasks.push(dataTask);
    }

    public handleFeedback(error: any, request?: any) {
        var msgs = this.baseRouteDeps.errorHandling.getErrorFeedbackMessages(error, request);
        msgs.forEach((msg) => {
            if (msg.feedbackMessageType === FeedbackMessageType.Error) {
                this.errors.push(msg);
            } else {
                this.warnings.push(msg);
            }
        });
    }

    protected handleValidationErrors(data: any) {
        if (data) {
            var msgs = this.baseRouteDeps.errorHandling.getValidationFeedback(data);
            msgs.forEach((msg) => {
                this.errors.push(msg);
            });
        }
    }

    protected clearFeedback() {
        this.errors = [];
        this.warnings = [];
    }

    protected destroy() {
        // called when the $scope emits the $destroy event, override in child class to do cleanup
    }

    protected dataLoaded(isReload?: boolean) {
        // called when all data is loaded
    }

    protected dataLoadFailed(errors: Array<any>) {
        // called when there was an error during the data load cycle
    }

    // Not needed anymore ???
    //protected dataProcessingFailed(errors: Array<any>) {
    // called when there was an error during the data processing cycle
    //}

    protected routeParamUpdated(params: Params): void {
        // called when route params are updated.
    }

    protected queryParamsUpdated(params: Params): void {
        // called when the query string parameters are updated
    }

    protected isDirty(): boolean {
        if (this.myForm) {
            return this.myForm.dirty;
        } else {
            return false;
        }
    }

    protected setPristine() {
        if (this.myForm) {
            this.myForm.form.markAsPristine();
        }
    }

    public isFormValid(form?: NgForm) {
        var formToValidate: NgForm = form || this.myForm;
        var isValid = true;

        if (formToValidate) {
            isValid = formToValidate.valid;
        }
        if (!isValid) {
            // trigger validation to show the validation errors in the UI
            this.validateForm(formToValidate);
        }

        return isValid;
    }

    private validateForm(form?: NgForm): boolean {
        var formToValidate: NgForm = form || this.myForm;

        var propertyNames: string[] = [];
        for (var propertyName in formToValidate.controls) {
            propertyNames.push(propertyName);
        }
        this.validateProperties(propertyNames, formToValidate);

        return formToValidate.valid;
    }

    private validateProperties(propertyNames: Array<string>, form?: NgForm) {
        var formToValidate: NgForm = form || this.myForm;

        propertyNames.forEach((propertyName) => {
            this.validateProperty(propertyName, formToValidate);
        });
    }

    private validateProperty(propertyName: string, form?: NgForm) {
        var formToValidate: NgForm = form || this.myForm;
        var property = formToValidate.controls[propertyName];

        if (property) {
            property.markAsDirty();
        } else {
            this.baseRouteDeps.logService.error('Property ' + propertyName + ' is not present');
            throw 'Property ' + propertyName + ' is not present';
        }
    }

    private resolveAllDataLoaded: (value?: any) => void;

    private initAllDataLoadedHandler() {
        this.allDataLoaded = new Promise((resolve, reject) => {
            this.resolveAllDataLoaded = resolve;
        });

        this.allDataLoaded.then(() => {

            this.dataLoaded.apply(this, [this._isLoaded]);

            // set active menu
            if (this.isCurrentRouteComponent() && this.activateMenuOptions) {
                setTimeout(() => { this.baseRouteDeps.menuService.activate(this.activateMenuOptions); }, 10);
            }

            // analytics
            if (false === this._isLoaded) {
                this._isLoaded = true;
                this.baseRouteDeps.analyticsService.controllerDataLoaded(this.name, this.loadStart);
            }
        });
    }

    private loadAllData(isLocaleChange: boolean): boolean {
        var self = this;
        var isLoadStarted: boolean = false;

        if (true === self._isLoading) {
            self.loadCalledAgain = true;
            self.baseRouteDeps.logService.log("loadAllData called when already loading. (" + self.name + ")");
            isLoadStarted = false;
        }

        if (false === self._isLoading) {
            self.startBlocking();

            isLoadStarted = true;
            self._isLoading = true;
            // for now, when resetDate is false, treat it as a localeChange
            self.initAllDataLoadedHandler();

            var dataLoaderPromises: Array<Promise<any>> = [];

            // push configured data loaders
            self.dataTasks.forEach((dataTask) => {
                var reload = true;
                if (self._initialized &&
                    ((isLocaleChange && !dataTask.reloadOnCultureChange) ||
                        (!isLocaleChange && !dataTask.reload))) {
                    reload = false;
                }
                if (dataTask.loader && reload) {
                    var promise = new Promise((resolve, reject) => {
                        dataTask.loader.apply(self, [resolve, reject]);
                    });

                    dataLoaderPromises.push(promise);
                }
            });

            Promise.all(dataLoaderPromises)
                .then((results: Array<any>) => {
                    self.processAllData(results, isLocaleChange)

                    if (self.loadCalledAgain) {
                        self.loadCalledAgain = false;
                        self.reload();
                        self.stopBlocking();
                    } else {
                        self.stopBlocking();
                    }
                })
                .catch((errors: Array<any>) => {
                    self.handleFeedback(errors);
                    self._isLoading = false;
                    self.dataLoadFailed.apply(self, [errors]);
                    self.stopBlocking();
                });
        }
        return isLoadStarted;
    }

    private processAllData(results: Array<any>, isLocaleChange: boolean) {
        var self = this;

        // push configured data processors
        var resultIndex = 0;
        for (var i: number = 0; i < self.dataTasks.length; i++) {
            var dataTask = self.dataTasks[i];
            var reload = true;
            if (self._initialized &&
                ((isLocaleChange && !dataTask.reloadOnCultureChange) ||
                    (!isLocaleChange && !dataTask.reload))) {
                reload = false;
            }
            if (dataTask.processor && reload) {
                var dataItem = null;
                if (dataTask.loader) {
                    dataItem = results[resultIndex];
                    resultIndex++;
                }

                dataTask.processor.apply(self, [dataItem]);

            } else if (reload) {
                // task has no processor, skip result
                resultIndex++;
            }
        }

        self.resolveAllDataLoaded();
        self._isLoading = false;
        self._initialized = true;
        self.setPristine();
    }

    // use this method after a save to process the data and set the form to pristine
    // todo : refactor this method to remove dependency on IDeferred and use IPromise
    protected refreshData<T>(data: T, processor: (data: T) => void, setPristine: boolean) {
        this.initAllDataLoadedHandler();
        processor.apply(this, [data]);
        this.resolveAllDataLoaded();
        if (setPristine) {
            this.setPristine();
        }
    }

    private onCultureChanged = (event: ICultureChangedEvent) => {
        var isLoadStarted: boolean;
        this.clearFeedback();

        // can be used to add actions on culture change in the controller
        this.baseRouteDeps.translateService.get("MyApp.LeavePage").subscribe((translation) => {
            this.leavePage = translation;
        });

        isLoadStarted = this.loadAllData(true);

        this.cultureChanged(event);
    }

    private onRefreshRequested = (event: boolean) => {
        this.handleRefreshRequested();
    }

    private onParamChange = (p: Params) => {
        var isInitialized: boolean = this.params !== undefined;
        this.params = p;

        // Do not call during intialization. Only when value is updated.
        if (isInitialized) {
            this.routeParamUpdated(this.params);
        }
    }

    private onQueryParamChange = (p: Params) => {
        var isInitialized: boolean = this.queryParams !== undefined;
        this.queryParams = p;

        if (p["isNewTab"]) {
            this.isNewTab = p["isNewTab"] === "true";
        }

        if (p["noCaptcha"] && this.baseRouteDeps.configuration.getAllowNoCaptchaUrlParameter()) {
            this.noCaptcha = p["noCaptcha"] == "1" || p["noCaptcha"] == "true";
        }

        // Do not call during intialization. Only when value is updated.
        if (isInitialized) {
            this.queryParamsUpdated(this.queryParams);
        }
    }

    private onLeavePageChosen = (result: boolean) => {
        this.handleLeavePageChosen(result);
    }

    protected handleLeavePageChosen(result: boolean) {
        if (this._historyBackRequested) {
            this._historyBackRequested = false;
            if (result) {
                this.doHistoryBack();
            }
        }
    }

    private isCurrentRouteComponent(): boolean {
        if (this.baseRouteDeps.route && this.baseRouteDeps.route.component instanceof Type) {
            var activeRouteComponent = this.baseRouteDeps.route.component;

            if (activeRouteComponent === Object.getPrototypeOf(this).constructor) {
                return true;
            }
        }

        return false;
    }

    // Called by the router when navigating away from the page
    // return true to continue navigation.
    //        false to cancel navigation.
    // !!! Need to init the "canDeactivete" property in the route config for this to work.
    // { path: 'site/:siteId', component: SiteDetailComponent, canDeactivate: [CanDeactivateGuardService] },
    public canDeactivate(): boolean | Observable<boolean> {
        // check on dirty
        // _leavePageDirtyAsked is possibly set by the historyBack()
        if (this._leavePageDirtyAsked === false && this.isDirty()) {
            this.baseRouteDeps.leavePageService.callDialogOpen();

            return this.baseRouteDeps.leavePageService.leavePageChoiceSubject.asObservable();
        }

        this._leavePageDirtyAsked = false;
        return true;
    }

    // Use this method to add logic in the child component
    protected cultureChanged(event: ICultureChangedEvent) {

    }

    protected routeChangedStart(event) {
        // can be used to add actions on routeChangeStart
    }

    protected getFilter(data) {
        if (!data.filter || !data.filter.filters || data.filter.filters.length === 0) {
            return null;
        }

        var merged: any = [];

        for (var i = 0; i < data.filter.filters.length; i++) {
            if (data.filter.filters[i].filters != null) {
                for (var j = 0; j < data.filter.filters[i].filters.length; j++) {
                    merged.push(data.filter.filters[i].filters[j]);
                }
            } else {
                merged.push(data.filter.filters[i]);
            }
        }

        return merged;
    }

    //logging
    public logInfo(arg1: any, ...arg2: any[]) {
        if (typeof arg1 == "string") {
            arg1 = this.name + " - " + arg1;
        }
        this.baseRouteDeps.logService.info(arg1, ...arg2);
    }

    public logWarning(arg1: any, ...arg2: any[]) {
        if (typeof arg1 == "string") {
            arg1 = this.name + " - " + arg1;
        }
        this.baseRouteDeps.logService.warn(arg1, ...arg2);
    }

    public logError(arg1: any, ...arg2: any[]) {
        if (typeof arg1 == "string") {
            arg1 = this.name + " - " + arg1;
        }
        this.baseRouteDeps.logService.error(arg1, ...arg2);
    }

    // utilities
    protected combinePostalAndCity(postal: string, city: string) {
        var combined = (postal || '') + '\u200C ' + (city || '');
        if (combined === '\u200C ') {
            combined = '';
        }
        return combined;
    }

    protected splitPostalAndCity(postalAndCity: string) {
        if (!postalAndCity) {
            postalAndCity = '';
        }
        var result = {
            postalCode: postalAndCity,
            city: ''
        };
        var indexOfSeparator = postalAndCity.indexOf('\u200C');
        if (indexOfSeparator > -1) {
            result.postalCode = postalAndCity.substr(0, indexOfSeparator);
            result.city = postalAndCity.substr(indexOfSeparator);
            result.city = result.city.replace('\u200C ', '');
        }
        return result;
    }

    protected numberToKendo(value: number, decimalNumbers?: number): string {
        var locale = this.baseRouteDeps.languageService.getLocale();
        
        if (locale) {
            if (decimalNumbers) {
                var options: Intl.NumberFormatOptions = {
                    minimumFractionDigits: decimalNumbers,
                    maximumFractionDigits: decimalNumbers
                }
                return value.toLocaleString([locale], options);
            } else {
                return value.toLocaleString([locale]);
            }
        }
    }

    ngOnDestroy() {
        for (var idx in this.activeWatches) {
            this.activeWatches[idx]();
        }

        // unsubscribe event listeners
        if (this.onCultureChangedSub) {
            this.onCultureChangedSub.unsubscribe();
        }

        if (this.onRefreshRequestedSub) {
            this.onRefreshRequestedSub.unsubscribe();
        }

        if (this.onParamChangeSub) {
            this.onParamChangeSub.unsubscribe();
        }

        if (this.onQueryParamChangeSub) {
            this.onQueryParamChangeSub.unsubscribe();
        }

        if (this.onLeavePageChosenSub) {
            this.onLeavePageChosenSub.unsubscribe();
        }

        this.baseRouteDeps.logService.info("Component destroy.");
        this.destroy();
    }
}