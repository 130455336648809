import { Component, Input, OnInit, Output, EventEmitter, Optional, Self } from "@angular/core";
import { ControlValueAccessor, NgModel } from "@angular/forms";


@Component({
    selector: "fp-password",
    templateUrl: "./fpPassword.component.html",
    host: { 'class': 'fp-control' }
})

export class FpPasswordComponent implements ControlValueAccessor, OnInit {
    public model: NgModel;

    @Input() name: string;
    // @Input() maxlength: number = 524288;

    
    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

    private innerValue: string = null;
    public controlId: string = "";

    constructor(
        @Optional() @Self() ngModel: NgModel | null) {
        this.model = ngModel;

        // Set the valueAccessor manually.
        // Before this was done by providing the component as NG_VALUE_ACCESSOR,
        //    but this causes a circular import when injecting NgModel in the constructor.
        if (this.model) {
            this.model.valueAccessor = this;
        }
    }

    onChange(_): void { }

    onTouched(): void { }

    ngOnInit(): void {
        this.controlId = this.name.replace('.', '_');
    }

    //get accessor
    get value(): string {
        if (this.innerValue && this.innerValue.length === 0) {
            return null;
        }

        return this.innerValue;
    };

    //set accessor including call the onchange callback
    set value(v: string) {
        if (v !== this.innerValue) {
            this.innerValue = v;

            if (this.onChange) {
                this.onChange(v);
            }

            if (this.valueChange) {
                this.valueChange.emit(v);
            }
        }
    }

    writeValue(value: string): void {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    onBlur() {
        this.onTouched();
    }

    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
}