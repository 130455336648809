<form #myForm="ngForm" novalidate>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-8" *ngIf="!data.mailSent">
            <div class="row">
                <div class="col-md-12">
                    <h1 fp-translate>MyApp.UsernameForgot_Title</h1>
                </div>
            </div>
            <div class="form-horizontal">
                <fp-error-display [errors]="errors"
                                  [warnings]="warnings"></fp-error-display>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <span fp-translate>MyApp.UsernameForgot_Text</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <fp-label label="Users.User_Email"></fp-label>
                </div>
                <div class="col-md-6">
                    <fp-textbox name="Users.User_Email"
                                [(ngModel)]="data.email"
                                maxlength="50"
                                required="true"></fp-textbox>
                </div>
                <div class="col-md-4">
                    <a style="line-height: 2.2" [routerLink]="['/fostpack1/password/forgot']">
                        <span fp-translate>MyApp.UsernameForgot_LinkToPasswordReset</span>
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">

                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="btn-toolbar">
                        <button class="btn btn-primary" (click)="submitForm()">
                            <fa-icon icon="user"></fa-icon>
                            <span fp-translate>MyApp.UsernameForgot_Submit</span>
                        </button>
                    </div>                  
                </div>
            </div>
        </div>

        <div class="col-md-6" *ngIf="data.mailSent">
            <div class="row success-text">
                <div class="col-md-2">
                    <fa-icon [icon]="['far', 'square-check']" size="5x"></fa-icon>
                </div>
                <div class="col-md-10">
                    <p fp-translate style="margin-top: 20px; margin-bottom: 5px;">MyApp.UsernameForgot_Confirm</p>
                    <a [routerLink]="['/fostpack1/password/forgot']" fp-translate>MyApp.UsernameForgot_LinkToPasswordReset</a>
                </div>
            </div>
        </div>
        <div class="col-md-3"></div>
    </div>

    <re-captcha size="invisible" *ngIf="!noCaptcha" #invisibleCaptcha (resolved)="onResolveCaptcha($event)" name="captcha" required></re-captcha>
</form>


